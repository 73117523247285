import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table';

const Ai = {
    monthlyHeadingText: 'Accrued Expenses Detail',
    formSections: [
        {
            heading: '',
            inputPlaceholder: 'Accrued Expenses Name',
            buttonText: 'Update',
            type: 'text'
        },
    ],
};

const head = ['Accrued Expense', 'Created At', 'Updated At', ''];

function AddAccruedExp() {
    const [year, setYear] = useState(localStorage.getItem("Year"));
    const [getAccruedExp, setgetAccruedExp] = useState([]);
    const [NewAccruedExp, setNewAccruedExp] = useState('');
    const [selectedAccruedExpId, setSelectedAccruedExpId] = useState(null);
    const [postError, setPostError] = useState(null);
    const [postLoading, setPostLoading] = useState(false);
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);

    const CompanyId = localStorage.getItem('CompanyId');

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "Year") {
                setYear(event.newValue);
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const fetchAccruedExps = async () => {
        setGetLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/add_accrued_exp_details?year=${year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }
            setgetAccruedExp(data.accr_exp_details || []);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading(false);
        }
    };

    useEffect(() => {
        fetchAccruedExps();
    }, [year]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    };

    const rows = getAccruedExp
        .filter(AccruedExp => AccruedExp && AccruedExp.accr_exp_name !== undefined)
        .map((AccruedExp, index) => ({
            id: index + 1,
            accr_exp_name: AccruedExp.accr_exp_name,
            created_at: formatDate(AccruedExp.created_at),
            updated_at: formatDate(AccruedExp.updated_at),
            button: <input type='button' className='inputboxChangeButton' onClick={() => changeAccruedExp(AccruedExp.accr_exp_name, AccruedExp.id)} value='Change' />
        }));

    const arrayOfArrays = rows.map(row => [
        // row.id,
        row.accr_exp_name,
        row.created_at,
        row.updated_at,
        row.button
    ]);

    const changeAccruedExp = (accruedExp, id) => {
        setNewAccruedExp(accruedExp);
        setSelectedAccruedExpId(id);
    };

    const handleButtonClick = async () => {
        if (!NewAccruedExp.trim()) {
            return;
        }
        setPostLoading(true);

        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            let url;
            let options;

            if (selectedAccruedExpId) {
                // Update existing accrued expense
                url = `${baseUrl}/update_accrued_exp_details?year=${year}&comp_id=${CompanyId}&id=${selectedAccruedExpId}`;
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ accr_exp_name: NewAccruedExp, CompanyId })
                };
            } else {
                // Create new accrued expense
                url = `${baseUrl}/create_accrued_exp_details?year=${year}&comp_id=${CompanyId}`;
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ accr_exp_name: NewAccruedExp, CompanyId })
                };
            }

            const response = await fetch(url, options);
            const result = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }

            setPostError(null);
            setNewAccruedExp('');
            setSelectedAccruedExpId(null);
            fetchAccruedExps();
        } catch (error) {
            setPostError(error.message);
        } finally {
            setPostLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setNewAccruedExp(e.target.value);
    };

    if (getLoading) return <div className='loader-container'>
    <div class="loader"></div>
</div>;

    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                AccruedExpAndServicesLabel="AccruedExp and Services"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleButtonClick}
                                handleInputChange={handleInputChange}
                                inputValue={NewAccruedExp}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <CustomTable head={head} rows={arrayOfArrays} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddAccruedExp;
