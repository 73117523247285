import { useState, useEffect } from 'react';

const useApi = ({ modal, CompanyId, method, postData }) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const Year = localStorage.getItem("Year")

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const year = Year; // Assuming year is constant or passed via props
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const url = `${baseUrl}/${modal}?year=${year}&comp_id=${CompanyId}`;
        console.log(url)
        const options = {
          method: method,
          headers: {
            'Content-Type': 'application/json'
          },
          body: method === 'POST' ? JSON.stringify(postData) : undefined // Use undefined for GET requests
        };

        const response = await fetch(url, options);
        
        // Log the entire response
        const text = await response.text();

        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
        }

        const result = JSON.parse(text);
        setResponse(result);
        setError(null);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [modal, CompanyId, method, postData]);

  return { response, loading, error };
};

export default useApi;
