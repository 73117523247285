import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import CustomTable from './Table/Table';
import { makeStyles } from "@material-ui/core/styles";
import Api from '../api/Api'; // Adjust the path based on your project structure

const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const head = ["Debt", ...Month];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]; // Assuming these are your month labels

function Debts() {
    const [Year, setYear] = useState(localStorage.getItem("Year"));
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    const [equityData, setEquityData] = useState(null); // State to store API response
    const [DropdownAutomationdata, setDropdownAutomationdata] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({}); // State to store selected options for each section
    const [inputValues, setInputValues] = useState({}); // State to store input values for each section
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);
    const [secondDropdown,setsecondDropdown] = useState([]);
    const [DebtsInputValue, setDebtsInputValue] = useState('');
    const [DebtsDropdown, setDebtsDropdown] = useState('');
    const [SelectedSecOptions, setSelectedSecOptions] = useState('');
    const { response: responseAutomation, loading: loadingAutomation, error: errorAutomation } = Api({
        modal: 'monthly_debt_entry',
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET'
    });

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "Year") {
                setYear(event.newValue);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        if (loadingAutomation) {
            console.log('Loading accrued expenses errorAutomation...');
        }
        if (errorAutomation) {
            console.error('Error fetching accrued expenses:', errorAutomation.message);
        }
        if (responseAutomation) {
            console.log('Automation fetched successfully:', responseAutomation);
            setDropdownAutomationdata(responseAutomation);
        }
    }, [responseAutomation, loadingAutomation, errorAutomation]);

    useEffect(() => {
        fetchData();
    }, [Year]);

    const handleOption1Change = (selectedOption, sectionIndex) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [sectionIndex]: {
                ...prevOptions[sectionIndex],
                option1: selectedOption
            }
        }));
    };

    const handleSelect2Change = (selectedOption, sectionIndex) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [sectionIndex]: {
                ...prevOptions[sectionIndex],
                option2: selectedOption
            }
        }));
    };

    const handleInputChange = (event, sectionIndex) => {
        const newValue = event.target.value;
        setInputValues(prevValues => ({
            ...prevValues,
            [sectionIndex]: newValue
        }));
    };

    const handleButtonClick = async (sectionIndex) => {
        const { option1, option2 } = selectedOptions[sectionIndex] || {};
        const amountValue = inputValues[sectionIndex];
        if (option1 && option2 && amountValue) {
            await handleUpdate(option1, option2, amountValue, sectionIndex);
            setInputValues(prevValues => ({
                ...prevValues,
                [sectionIndex]: ''
            }));
        } else {
            console.error('Missing required fields for section', sectionIndex);
        }
    };

    const handleUpdate = async (dropdownValue, monthValue, amountValue, sectionIndex) => {
        console.log('dropdownValue', dropdownValue);
        console.log('monthValue', monthValue);
        console.log('amountValue', amountValue);
        setGetLoading(true);
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/update/debtnew?year=${Year}&comp_id=${CompanyId}`;
            const requestBody = {
                modeltype: sectionIndex === 0 ? 'ShortTermDebt' : sectionIndex === 1 ? 'longTermDebt' : 'InterestExpense',
                dropdown: dropdownValue,
                month: monthValue,
                Amount: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error updating income tax data:', error);
        }
    };

    const fetchData = async () => {
        setGetLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/calculate-debts?year=${Year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }
            setEquityData(data);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading(false);
        }
    };

    const sections2 = [
        'Short Term Debt',
        'Long-Term Debt',
        'Interest Expense'
    ];

    const salesData = {
        monthlyHeadingText: 'Monthly Debt Entry',
        formSections: [
            {
                heading: 'Short Term Debt',
                selectOptions1: (DropdownAutomationdata?.ShortTermDebt || []).map(item => ({
                    value: item,
                    label: item
                })),
                selectOptions2: Month.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                onSelectOption1Change: (selectedOption) => handleOption1Change(selectedOption, 0),
                onSelectOption2Change: (selectedOption) => handleSelect2Change(selectedOption, 0),
                onInputChange: (event) => handleInputChange(event, 0),
                onButtonClick: () => handleButtonClick(0),
                type: 'number'
            },
            {
                heading: 'Long-Term Debt',
                selectOptions1: (DropdownAutomationdata?.longTermDebt || []).map(item => ({
                    value: item,
                    label: item
                })),
                selectOptions2: Month.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                onSelectOption1Change: (selectedOption) => handleOption1Change(selectedOption, 1),
                onSelectOption2Change: (selectedOption) => handleSelect2Change(selectedOption, 1),
                onInputChange: (event) => handleInputChange(event, 1),
                onButtonClick: () => handleButtonClick(1),
                type: 'number'
            },
            {
                heading: 'Interest Expense',
                selectOptions1: (DropdownAutomationdata?.InterestExpense || []).map(item => ({
                    value: item,
                    label: item
                })),
                selectOptions2: Month.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Interest Rate Montly',
                buttonText: 'Update',
                onSelectOption1Change: (selectedOption) => handleOption1Change(selectedOption, 2),
                onSelectOption2Change: (selectedOption) => handleSelect2Change(selectedOption, 2),
                onInputChange: (event) => handleInputChange(event, 2),
                onButtonClick: () => handleButtonClick(2),
                type: 'number'
            },
        ],
    };

    const rows = [
        ['Short Term Debt',],
        ['Beginning Month Balance', ...months.map(month => equityData?.short_term_beg_bal[month] || '0')],
        ['Loan Raised', ...months.map(month => equityData?.short_loan_raised[month] || '0')],
        ['From Long-Term Debt', ...months.map(month => equityData?.from_long_term_debt[month] || '0')],
        ['Repayment of Debt', ...months.map(month => equityData?.short_repay_debt[month] || '0')],
        ['End of Month Balance', ...months.map(month => equityData?.short_end_bal[month] || '0')],
        ['Long-Term Debt',],
        ['Beginning Month Balance', ...months.map(month => equityData?.long_term_beg_bal[month] || '0')],
        ['Loan Raised', ...months.map(month => equityData?.long_loan_raised[month] || '0')],
        ['To Short-Term Debt', ...months.map(month => equityData?.to_short_term_debt[month] || '0')],
        ['End of Month Balance', ...months.map(month => equityData?.long_end_bal[month] || '0')],
        ['',],
        ['Total End of Month Balance', ...months.map(month => equityData?.final_sum[month] || '0')],
        ['',],
        ['Interest Expenses',],
        ['Interest Rate Monthly', ...months.map(month => equityData?.int_rate[month] + "%" || '0%')],
        ['Interest Expense', ...months.map(month => parseInt(equityData?.interest_expense[month]) || '0')],
    ];

    const handleOptionAuto = (selectedOption, sectionIndex) => {
       console.log("selectedOption "+selectedOption)

       if(selectedOption=="ShortTermDebt"){
        setsecondDropdown((DropdownAutomationdata?.ShortTermDebt || []).map(item => ({
            value: item,
            label: item
        })))
       }
       else if(selectedOption=="longTermDebt"){
        setsecondDropdown((DropdownAutomationdata?.longTermDebt || []).map(item => ({
            value: item,
            label: item
        })))
       }
       if(selectedOption=="InterestExpense"){
        setsecondDropdown((DropdownAutomationdata?.InterestExpense || []).map(item => ({
            value: item,
            label: item
        })))
       }
       setDebtsDropdown(selectedOption)
       console.log(secondDropdown)
    };

    const Ai = {
        monthlyHeadingText: 'Automation Entry',
        formSections: [
            {
                heading: '',
                selectOptions1: Object.keys(DropdownAutomationdata || {}).map((item, index) => ({
                    value: item,
                    label: sections2[index]
                })),
                selectOptions2: secondDropdown, // Initially empty
                inputPlaceholder: 'Amount',
                buttonText: 'Start Automation',
                type: 'number'
            },
        ],
    };

    const handleDebtsInputChange = (event) => {
        const newValue = event.target.value;
        setDebtsInputValue(newValue);
        console.log(`Automation Input Value: ${newValue}`);
        // Perform any additional logic here
    };
    
    const handleSelectDebtChange = (selectedOption, sectionIndex) => {
        setSelectedSecOptions(selectedOption);
    };
    
    const handleDebtsButtonClick = async () => {
        const dropdownValue = DebtsDropdown;
        const dropdownSecValue = SelectedSecOptions;
        const amountValue = DebtsInputValue;

        handleDebtsTaxUpdate(dropdownValue, dropdownSecValue, amountValue);
        setDebtsInputValue('')
    };

    const handleDebtsTaxUpdate = async (dropdownValue, dropdownSecValue, amountValue) => {
        setGetLoading(true);
        console.log(
            `Dropdown Value: ${dropdownValue}, Dropdown Sec Value: ${dropdownSecValue}, Amount Value ${amountValue}`
        )
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/automation?year=${Year}&comp_id=${CompanyId}`
            const requestBody = {
                dropdown: dropdownSecValue,
                module: "debts",
                subModule: dropdownValue,
                value: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error updating equity:', error);
        }
    };

    if (getLoading) return <div className='loader-container'>
    <div class="loader"></div>
</div>;

    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>Automation Entry</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                productAndServicesLabel="Product and Services"
                                selectPlaceholder="Select Value"
                                handleButtonClick={() => handleDebtsButtonClick()}
                                handleSelect1Change={handleOptionAuto}
                                handleSelect2Change={handleSelectDebtChange}
                                handleInputChange={(event) => handleDebtsInputChange(event)}
                                inputValue={DebtsInputValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>Monthly Debt Entry</span>
                            </div>
                            <FormSection
                                sections={salesData.formSections}
                                productAndServicesLabel="Select type Details"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleButtonClick}
                                handleInputChange={handleInputChange}
                                handleSelect1Change={handleOption1Change}
                                handleSelect2Change={handleSelect2Change}
                                inputValues={inputValues}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        {/* Conditionally render table when equityData is available */}
                        {equityData && Array.isArray(rows) && rows.length > 0 && (
                            <CustomTable head={head} rows={rows} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Debts;
