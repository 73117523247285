import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import '../assets/css/LogIn.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';


const loginimg = require('../assets/img/cashflowlogin.png');
const logo = require('../assets/img/logoicon.png');
const profile1 = require('../assets/img/homeProfile.png');
const profile3 = require('../assets/img/Profile3.webp');
const profile4 = require('../assets/img/Profile4.jpg');

const imageTexts = [
  { image: require('../assets/img/homeProfile.png'), text: " It’s Awesome!" },
  { image: require('../assets/img/Profile3.webp'), text: " Keep Going!" },
  { image: require('../assets/img/Profile4.jpg'), text: " You Can Do It!" }
];
const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Signup form states
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  // Forgot Password states
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [loginErrorforgot, setLoginErrorforgot] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipping, setIsFlipping] = useState(false);
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (imageTexts[currentIndex]) {
      const fullText = imageTexts[currentIndex].text;
      setDisplayedText(''); // Reset displayed text when index changes
      let currentCharIndex = 0;

      const typingSpeed = 100; // Milliseconds per character
      const typingInterval = setInterval(() => {
        if (currentCharIndex < fullText.length-1) {
          setDisplayedText((prev) => prev + fullText[currentCharIndex]);
          currentCharIndex++;
        } else {
          clearInterval(typingInterval); // Stop typing when complete
        }
      }, typingSpeed);

      return () => clearInterval(typingInterval); // Cleanup on unmount or index change
    }
  }, [currentIndex]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFlipping(true); // Start flip animation
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageTexts.length); // Change image/text
        setIsFlipping(false); // End flip animation
      }, 1000); // Duration of flip animation (match with CSS)
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoginError(false);
    setErrorMessage('');

    const url = 'https://laravel.cashflow365.se/api/admincashflow/login';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: username, password: password }),
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();

      if (data.message === true) {
        console.log(data)
        localStorage.setItem('CompanyId', data.user);
        localStorage.setItem('UserName', username);
        localStorage.setItem('Profile', data.filename);
        onLogin();
        setIsAuthenticated(true);
      } else {
        setLoginError(true);
        setErrorMessage('Incorrect username or password.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setLoginError(true);
      setErrorMessage('Incorrect username or password.');
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    setLoginError(false);
    setErrorMessage('');

    if (signupPassword !== retypePassword) {
      setLoginError(true);
      setErrorMessage('Passwords do not match.');
      return;
    }

    const signupUrl = 'https://laravel.cashflow365.se/api/account/signup';
    const signupRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        firstname: firstName,
        lastname: lastName,
        companyname: companyName,
        phonenumber: phoneNumber,
        city: city,
        country: country,
        email: email,
        password: signupPassword,
      }),
    };

    try {
      const response = await fetch(signupUrl, signupRequestOptions);
      if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
      const data = await response.json();
      console.log('Signup success:', data);

      if (data[0] === 'E-mail already Register') {
        setLoginError(true);
        setErrorMessage('E-mail already Register');
        return;
      }

      setShowSignup(false);
      setFirstName('');
      setLastName('');
      setCompanyName('');
      setPhoneNumber('');
      setCity('');
      setCountry('');
      setEmail('');
      setSignupPassword('');
      setRetypePassword('');
      setAgreeTerms(false);

      const userInfo = data[0];
      console.log('user', userInfo);

      const sendEmailUrl = 'https://laravel.cashflow365.se/api/send/sendmail?year=2023&comp_id=demo1';
      const sendEmailRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({
          content: `UserName ${userInfo.Login_id} Password ${userInfo.password}`,
          mailid: userInfo.Login_id,
        }),
      };

      console.log('Sending email with body:', sendEmailRequestOptions.body);

      const emailResponse = await fetch(sendEmailUrl, sendEmailRequestOptions);
      const emailData = await emailResponse.json();
      console.log('Mail success:', emailData);
    } catch (error) {
      console.error('Error signing up:', error);
      setLoginError(true);
      setErrorMessage('Error signing up. Please try again later.');
    }
  };


  //forgot password
  const generateOTP = () => Math.floor(100000 + Math.random() * 900000).toString();

  const sendOtpToEmail = async (Genotp, email) => {
    try {
      const response = await fetch('https://laravel.cashflow365.se/api/send/sendmail?year=2023&comp_id=demo1', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ content: `Forgot Password OTP ${Genotp}`, mailid: email }),
      });

      if (!response.ok) throw new Error('Failed to send OTP');
      const data = await response.json();
      console.log('OTP sent successfully:', data);
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };
  const [generateOTPdata, setgenerateOTPdata] = useState('')
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    if (forgotPasswordStep === 1) {
      document.getElementById('sendotpbtn').setAttribute("disabled", true);
      const Genotp = generateOTP();
      setgenerateOTPdata(Genotp);
      await sendOtpToEmail(Genotp, forgotPasswordEmail);
      document.getElementById('sendotpbtn').disabled = true;
      setForgotPasswordStep(2);
    } else if (forgotPasswordStep === 2) {
      document.getElementById('verifyotpbtn').setAttribute("disabled", true);
      if (otp === generateOTPdata) {
        setNewPasswordModal(true);
      } else {
        setLoginErrorforgot(true);
        setErrorMessage('Invalid OTP.');
        document.getElementById('verifyotpbtn').disabled = false;
      }
    }
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    setLoginErrorforgot(false);
    setErrorMessage('');

    const updatePasswordUrl = 'https://laravel.cashflow365.se/api/update/forgotPass';
    const updatePasswordRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userName: forgotPasswordEmail,
        Password: newPassword,
      }),
    };

    try {
      const response = await fetch(updatePasswordUrl, updatePasswordRequestOptions);
      if (!response.ok) throw new Error('Failed to update password');
      const data = await response.json();
      console.log('Password update success:', data);

      setNewPasswordModal(false);
      setShowForgotPassword(false);
      setForgotPasswordStep(1);
      setForgotPasswordEmail('');
      setOtp('');
      setNewPassword('');

    } catch (error) {
      console.error('Error updating password:', error);
      setLoginErrorforgot(true);
      setErrorMessage('Failed to update password. Please try again later.');
    }
  };


  const [logindasboard, setLoginDashboard] = useState(false)
  const [dashboardbox, setDashboardbox] = useState(true)
  const setLoginDashboardlogin = () => {
    setLoginDashboard(true);
    setDashboardbox(false)
    setShowSignup(false)
  }
  const setLoginDashboardSign = () => {
    setLoginDashboard(true);
    setDashboardbox(false)
    setShowSignup(true)
  }
  const highlights = [
    {
      title: "Automated Accounting",
      description: "Simplify your bookkeeping with automated accounting features that reduce errors and save time.",
      tag: "Automation",
      tagClass: "text-success",
      background: "bg-custom2"
    },
    {
      title: "Publishing AI Reports",
      description: "Generate insightful reports, receive financial suggestions, and integrate external systems for enhanced financial management.",
      tag: "AI Integrations",
      tagClass: "text-primary",
      background: "bg-custom3"
    },
    {
      title: "Expense Management",
      description: "Track and control your expenses effortlessly with our intuitive expense management system.",
      tag: "Easy Management",
      tagClass: "text-warning",
      background: "bg-custom4"
    },
    {
      title: "Cashflow Management",
      description: "Fast, accurate calculations for seamless financial planning and management.",
      tag: "Fast Invoicing",
      tagClass: "text-danger",
      background: "bg-custom5"
    },
    {
      title: "Real-Time Analytics",
      description: "Gain valuable insights with real-time analytics that provide a clear picture of your financial health.",
      tag: "Analytics",
      tagClass: "text-warning",
      background: "bg-custom6",
      isComingSoon: true
    },
    {
      title: "Integration",
      description: "Seamlessly integrate Cashflow with your existing tools and software for a smooth financial management experience.",
      tag: "Easy to use",
      tagClass: "text-primary",
      background: "bg-custom"
    },
  ];
  const logo = require('../assets/img/logoicon.png');
  const FinaImg = require('../assets/img/FinaImg.png');
  const profile = require('../assets/img/homeProfile.png');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenDemo, setModalIsOpenDemo] = useState(false);
  const [demomessage, setDemomessage] = useState('Kindly share available time for demo of cashflow365 application.');
  const openModal = () => {
    setModalIsOpen(true);
  };
  const bookdemo = () => {
    setModalIsOpenDemo(true);
  }
  const sendDemoMessage = async (e) => {
    e.preventDefault();
    console.log("demomessage  " + demomessage)
    try {
      const response = await fetch('https://laravel.cashflow365.se/api/send/sendmail?year=2023&comp_id=demo1', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ content: ` ${demomessage}`, mailid: "allan@voreck.se " }),
      });

      if (!response.ok) throw new Error('Failed to send mail');
      const data = await response.json();
      alert('mail sent successfully');
      setModalIsOpenDemo(false);
    } catch (error) {
      console.error('Error sending mail:', error);
    }
  }

  const closeModalDemo = () => {
    setModalIsOpenDemo(false);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }



  return (
    <>
      {dashboardbox && (
        <div>
          <div className="">
            <div className="row">
              <div className="col-lg-7" style={{ padding: '0px' }}>
                <div>
                  <div className="Dashboard-container-box">
                    <div className="logo-container">
                      <img src={logo} alt="logo" />
                    </div>
                    <div className="logo-Revolutionize">
                      <div className="text-Revolutionize">Revolutionize Finance Industry</div>
                    </div>
                    <div className="Dashboard-text mt-4">
                      <div>
                        <span className="Dashboard-cash-flow-text">
                          Empower your business with <span className="text-cashflow">Cashflow 365</span>
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 para-container">
                      <p className="para-text">
                        Cashflow is your ultimate SaaS solution, simplifying and streamlining finance for any business.
                      </p>
                    </div>
                    <div className="mt-1">
                      <button className="Demo-Button" onClick={bookdemo}>Book Demo</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5" style={{ padding: '0px' }}>
                <div className="Dashboard-Profile-box">
                  <nav>
                    <ul className="navbarBoxDashboard" >
                      <li className="Dashboard-Profile-List Dashboard-Profile-List-Features dropdown">
                        <a href="#" className="dropdown-toggle navItem">Features</a>
                        <ul className="dropdown-menu">
                          <li><a href="#Key">Automotion</a></li>
                          <li><a href="#Key">AI Integrations</a></li>
                          <li><a href="#Key">Easy Management</a></li>
                          <li><a href="#Key">Fast Invoicing</a></li>
                          <li><a href="#Key">Analytics</a></li>
                          <li><a href="#Key">Easy to use</a></li>
                        </ul>
                      </li>
                      <li className="Dashboard-Profile-List navItem">
                        <a href="#" onClick={openModal}>Contact</a>
                      </li>
                      <li className='navItem'>
                        <input type="button" className="Dashboard-Profile-List-signup-btnbox" value='Sign Up' onClick={setLoginDashboardSign} />
                      </li>
                      <li className='navItem'>
                        <input type="button" className="Dashboard-Profile-List-Login-btnbox" value='Login' onClick={setLoginDashboardlogin} />
                      </li>
                    </ul>
                  </nav>
                  <div className="profile">
                    <div className="profile-img-container">
                      <div className={`flip-container ${isFlipping ? 'flipping' : ''}`}>
                        <div className="flipper">
                          {imageTexts[currentIndex] && (
                            <img
                              src={imageTexts[currentIndex].image}
                              className="profile-img"
                              alt="profile"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Profile-container">
                      <div className="Profile-text">
                        <span className="profile-name">{displayedText}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container my-5" name='Key' id='Key'>
            <h2 className="text-center mb-4">Key Highlights</h2>
            <div className="row">
              {highlights.map((highlight, index) => (
                <div key={index} className={`col-md-4 p-3 fill `}>
                  <div className={`card p-4 border rounded bg-light flex-fill  ${highlight.tag}`}>
                    <input type="button" value={highlight.tag} className={`d-block mb-2 ${highlight.tagClass} ${highlight.background}`} style={{ border: 'none', width: '50%', borderRadius: '10px' }} />
                    <h5>{highlight.title}</h5>
                    <p>{highlight.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Other sections... */}
          <div className=" mt-5">
            <div className="row">
              <div className="col-md-10 bg-light align-items-center rounded" style={{ position: 'relative', }}>
                <div className="p-3" style={{ paddingLeft: '100px!important', backgroundColor: '#F2FFF1' }}>
                  <div className="p-5" style={{ marginRight: '100px' }}>
                    <h2 className=""><span style={{ color: '#5FA55A' }}>Dashboard:</span> Your Financial Command Center</h2>
                    <p className="p-4" style={{ padding: '30px' }}>
                      Gain complete control over financial reporting with CashFlow365's Dashboard. Tailor and customize reports and metrics to meet your business needs, providing precise insights for strategic planning and decision-making.
                    </p>
                    <ul className="list-unstyled">
                      <li className="d-flex align-items-center mb-2">
                        <span className="badge badge-success mr-2">✔</span> Business-Specific Reports
                      </li>
                      <li className="d-flex align-items-center mb-2">
                        <span className="badge badge-warning mr-2" style={{ color: '#5FA55A' }}>✔</span> Custom Key Figures
                      </li>
                      <li className="d-flex align-items-center">
                        <span className="badge badge-warning mr-2" style={{ color: '#5FA55A' }}>✔</span> Embedded Metrics
                      </li>
                    </ul>
                  </div>
                </div>
                <img src={FinaImg} alt="Sales Inventory" className="img-fluid rounded" style={{ position: 'absolute', top: '10%', right: '-150px', width: '300px', height: '300px' }} />
              </div>
            </div>
          </div>
          <div className=" mt-5">
            <div className="row d-flex justify-content-end">
              <div className="col-md-10 align-items-center rounded" style={{ position: 'relative', backgroundColor: '#E1FDFF' }}>
                <div className="p-5" style={{ marginLeft: '130px' }}>
                  <h2 className=""><span style={{ color: '#01B4BC' }}>AI-Driven Reports</span> and Key Figures</h2>
                  <p className="p-4" >
                    Harness AI with CashFlow365 for tailored insights. Create precise decision-making documents for management and the board. Customize reports and metrics to gain exact insights for strategic planning.
                  </p>
                  <ul className="list-unstyled">
                    <li className="d-flex align-items-center mb-2">
                      <span className="badge badge-warning mr-2">✔</span>AI generates reports tailored to your business
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <span className="badge badge-warning mr-2">✔</span>Track the most relevant metrics.
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="badge badge-warning mr-2">✔</span> Informed decision-making
                    </li>
                  </ul>
                </div>
                <img src={FinaImg} alt="Sales Inventory" className="img-fluid rounded" style={{ position: 'absolute', top: '10%', left: '-170px', width: '300px', height: '300px' }} />
              </div>
            </div>

          </div>
          <div className=" mt-5">
            <div className="row">
              <div className="col-md-10 bg-light align-items-center rounded" style={{ position: 'relative', }}>
                <div className="p-3" style={{ paddingLeft: '100px!important', backgroundColor: '#FFF2E7' }}>
                  <div className="p-5" style={{ marginRight: '100px' }}>
                    <h2 className=""><span style={{ color: '#FA8925' }}>Sales Inventory:</span> Management</h2>
                    <p className="p-4" style={{ padding: '30px' }}>
                      Optimize your sales with CashFlow365's inventory management. Monitor and manage inventory in real-time, ensuring the right products at the right time. Generate accurate reports and forecasts to enhance your sales strategy.
                    </p>
                    <ul className="list-unstyled">
                      <li className="d-flex align-items-center mb-2">
                        <span className="badge badge-success mr-2">✔</span> Always know your stock levels.
                      </li>
                      <li className="d-flex align-items-center mb-2">
                        <span className="badge badge-warning mr-2" style={{ color: '#5FA55A' }}>✔</span> Generate detailed sales inventory reports.
                      </li>
                      <li className="d-flex align-items-center">
                        <span className="badge badge-warning mr-2" style={{ color: '#5FA55A' }}>✔</span> Optimize your sales strategy.
                      </li>
                    </ul>
                  </div>
                </div>
                <img src={FinaImg} alt="Sales Inventory" className="img-fluid rounded" style={{ position: 'absolute', top: '10%', right: '-150px', width: '300px', height: '300px' }} />
              </div>
            </div>
          </div>
          <div className=" mt-5">
            <div className="row d-flex justify-content-end">
              <div className="col-md-10 align-items-center rounded" style={{ position: 'relative', backgroundColor: '#FFF7E5' }}>
                <div className="p-5" style={{ marginLeft: '130px' }}>
                  <h2 className=""><span style={{ color: '#F9AE01' }}>Income Tax </span> Management</h2>
                  <p className="p-4" >
                    Simplify your income tax process with CashFlow365. Manage, calculate, and file your taxes accurately and efficiently. Gain insights into tax liabilities and optimize your tax strategy for better planning.
                  </p>
                  <ul className="list-unstyled">
                    <li className="d-flex align-items-center mb-2">
                      <span className="badge badge-warning mr-2">✔</span>Ensure precise income tax calculations.
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <span className="badge badge-warning mr-2">✔</span>Streamline the entire tax process.
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="badge badge-warning mr-2">✔</span> Generate detailed tax reports for better planning.
                    </li>
                  </ul>
                </div>
                <img src={FinaImg} alt="Sales Inventory" className="img-fluid rounded" style={{ position: 'absolute', top: '10%', left: '-170px', width: '300px', height: '300px' }} />
              </div>
            </div>

          </div>

          <div className="container">
            <div className="demo-section">
              <h2 className="hedingdash">Explore CashFlow365 from Within</h2>
              <p className="hedingdash-p">Experience CashFlow365 for free with no obligations by opening a test account, or schedule a free demonstration to see it in action.</p>
              <button className="btnbox btn btn-primary" style={{ borderRadius: '50px', padding: '7px', paddingLeft: '15px', paddingRight: '15px' }} onClick={bookdemo}>Book Demo</button>
            </div>
          </div>
        </div>
      )}

      {/* Modal Component */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Contact Information"
        className="Modal__Content" // Apply content styles
        overlayClassName="Modal__Overlay" // Apply overlay styles
      >
        <div style={{ padding: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <div style={{ padding: '10px' }}>
            <h2 style={{
              color: '#114CB2',
            }}>Contact</h2>
          </div>
          <div style={{ display: 'flex' }}>
            <p style={{
              marginBottom: '10px',
              fontSize: '16px',
              borderRadius: '3px'
            }}>Email: </p><p style={{ marginLeft: '20px' }}>allan@voreck.se</p>
          </div>
          <div className='d-flex'>
            <p style={{
              marginBottom: '10px',
              fontSize: '16px',
              borderRadius: '3px'
            }}>Company:</p><p style={{ marginLeft: '20px' }}>CashFlow WebApp AB</p>
          </div>
          <div className='d-flex'>
            <p style={{
              marginBottom: '20px',
              fontSize: '16px',
              borderRadius: '3px'
            }}>Address: </p><p style={{ marginLeft: '20px' }}>Hälsingegatan 49, 113 31 Stockholm</p>
          </div>
          <button
            onClick={closeModal}
            style={{
              padding: '5px 20px',
              backgroundColor: '#f1f1f1',
              color: '#000000',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '16px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' // Box shadow for the button
            }}
          >
            Close
          </button>
        </div>

      </Modal>
      <Modal isOpen={modalIsOpenDemo}
        onRequestClose={closeModalDemo}
        contentLabel="Contact Information"
        className="Modal__Content"
        overlayClassName="Modal__Overlay" >
        <form onSubmit={sendDemoMessage} >
          <div className="form-group">
            <div>
              <label htmlFor="forgotPasswordEmail">Enter Your Message</label>
            </div>
            <div>
              <textarea
                type="textarea"
                value={demomessage}
                onChange={(e) => setDemomessage(e.target.value)}
                style={{ width: '500px', marginTop: '20px' }}
                required
              />
            </div>
          </div>
          {loginErrorforgot && <p className="text-danger">{errorMessage}</p>}
          <div className='mt-4'>
            <button type="submit" className="btn btn-primary" id='sendotpbtn'>Send</button>
          </div>
        </form>
      </Modal>
      {logindasboard && (
        <div>
          <nav className="navbar navbar-expand-lg navbar-light px-lg-5  navbar-login" style={{ position: "fixed", width: '100%', top: 0, zIndex: 999, backgroundColor: 'white' }}>

            <div className="container-fluid">
              <a className="navbar-brand" href="#"><img src={logo} alt="Logo" onClick={() => { setDashboardbox(true); setLoginDashboard(false) }} /></a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse justify-content-end" id="navbarScroll">
                <form className="d-flex mx-lg-4">
                  <button
                    className={showSignup ? 'btn btn-primary btn-LogIn' : 'btn btn-primary btn-SignUp'}
                    type="button"
                    onClick={() => setShowSignup(true)}
                  >
                    Sign Up
                  </button>
                  <button
                    className={showSignup ? 'btn btn-primary btn-SignUp' : 'btn btn-primary btn-LogIn'}
                    type="button"
                    onClick={() => setShowSignup(false)}
                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
          </nav>
          <div className="row no-gutters" style={{ marginTop: '85px', paddingBottom: '0px' }}>
            <div className="col-lg-6" style={{ backgroundColor: 'white' }}>
              {showSignup && (
                <div className="signup-form-container mt-5">
                  <h2>Welcome To  <span style={{ color: '#0660FC' }}> Cashflow 365</span></h2>
                  <form onSubmit={handleSignupSubmit}>
                    <div className="row mt-4 no-gutters">
                      <div className="col-md-6">
                        <div className="form-group pe-4">
                          <label htmlFor="firstName" style={{ color: 'gray', fontSize: '14px' }} className='mt-2'>First Name</label>
                          <input
                            type="text"
                            className="pass"
                            id="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group ps-4">
                          <label htmlFor="lastName" style={{ color: 'gray', fontSize: '14px' }} className='mt-2'>Last Name</label>
                          <input
                            type="text"
                            className="pass"
                            id="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="companyName" style={{ color: 'gray', fontSize: '14px' }} className='mt-2'>Company Name</label>
                      <input
                        type="text"
                        className="pass"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phoneNumber" style={{ color: 'gray', fontSize: '14px' }} className='mt-2'>Phone Number</label>
                      <input
                        type="text"
                        className="pass"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="city" style={{ color: 'gray', fontSize: '14px' }} className='mt-2'>City</label>
                      <input
                        type="text"
                        className="pass"
                        id="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="country" style={{ color: 'gray', fontSize: '14px' }} className='mt-2'>Country</label>
                      <input
                        type="text"
                        className="pass"
                        id="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email" style={{ color: 'gray', fontSize: '14px' }} className='mt-2'>Email</label>
                      <input
                        type="email"
                        className="pass"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="signupPassword" style={{ color: 'gray', fontSize: '14px' }} className='mt-2'>Password</label>
                      <input
                        type="password"
                        className="pass"
                        id="signupPassword"
                        value={signupPassword}
                        onChange={(e) => setSignupPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="retypePassword" style={{ color: 'gray', fontSize: '14px' }} className='mt-2'>Retype Password</label>
                      <input
                        type="password"
                        className="pass"
                        id="retypePassword"
                        value={retypePassword}
                        onChange={(e) => setRetypePassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className=""
                        id="agreeTerms"
                        checked={agreeTerms}
                        onChange={(e) => setAgreeTerms(e.target.checked)}
                        required
                      />
                      <label className="form-check-label mt-3" style={{ marginLeft: '20px' }} htmlFor="agreeTerms">I agree to the terms and conditions</label>
                    </div>
                    {loginError && <p className="text-danger">{errorMessage}</p>}
                    <div className='mt-3'>
                      <button type="submit" className="btn btn-primary btn-SignUp2"> Create an account</button>
                    </div>
                  </form>
                </div>
              )}

              {!showSignup && (
                <div className="login-form-container mt-2 ">
                  <h2>Login to  <span style={{ color: '#0660FC' }}> Cashflow 365</span></h2>
                  <form onSubmit={handleLoginSubmit}>
                    <div className="form-group mt-5">
                      <label htmlFor="username" style={{ color: '#666666' }} className='logotext'>Username</label>
                      <input
                        type="text"
                        className="pass"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mt-4">
                      <label htmlFor="password" style={{ color: '#666666' }} className='logotext'>Password</label>
                      <input
                        type="password"
                        className="pass"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    {loginError && <p className="text-danger">{errorMessage}</p>}
                    <button type="submit" className="btn btn-primary btn-LogIn2 mt-5">Login</button>
                  </form>
                  <div className='mt-4' style={{ cursor: 'pointer' }}>
                    <span
                      className=" btn-link mt-5"
                      onClick={() => setShowForgotPassword(true)}
                    >
                      Forgot Password?
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-6 p-0" style={{ position: 'fixed', right: 0 }}>
              <img src={loginimg} alt="Login" className=" login-image" />
            </div>
          </div>

          {showForgotPassword && (
            <div className="modal show d-block">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Forgot Password</h5>
                    <button type="button" className="close" onClick={() => setShowForgotPassword(false)}>
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {forgotPasswordStep === 1 && (
                      <form onSubmit={handleForgotPasswordSubmit}>
                        <div className="form-group">
                          <label htmlFor="forgotPasswordEmail">Enter your email address</label>
                          <input
                            type="email"
                            className="pass"
                            id="forgotPasswordEmail"
                            value={forgotPasswordEmail}
                            onChange={(e) => setForgotPasswordEmail(e.target.value)}
                            required
                          />
                        </div>
                        {loginErrorforgot && <p className="text-danger">{errorMessage}</p>}
                        <div className='mt-4'>
                          <button type="submit" className="btn btn-primary" id='sendotpbtn'>Send OTP</button>
                        </div>
                      </form>
                    )}
                    {forgotPasswordStep === 2 && (
                      <form onSubmit={handleForgotPasswordSubmit}>
                        <div className="form-group">
                          <label htmlFor="otp">Enter the OTP sent to your email</label>
                          <input
                            type="text"
                            className="pass"
                            id="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                          />
                        </div>
                        {loginErrorforgot && <p className="text-danger">{errorMessage}</p>}
                        <div className='mt-4'>
                          <button type="submit" className="btn btn-primary" id='verifyotpbtn'>Verify OTP</button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {newPasswordModal && (
            <div className="modal show d-block">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Reset Password</h5>
                    <button type="button" className="close" onClick={() => setNewPasswordModal(false)}>
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      // handle new password logic here
                    }}>
                      <div className="form-group">
                        <label htmlFor="newPassword">New Password</label>
                        <input
                          type="password"
                          className="pass"
                          id="newPassword"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className='mt-4'>
                        <button type="submit" className="btn btn-primary" onClick={handleNewPasswordSubmit}>Reset Password</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LoginForm;
