import React, { useState } from 'react';
import FormSection from '../../components/Form/FormSection';
const Year = process.env.REACT_APP_API_BASE_Year;

const UpdateEmail = () => {
    const [newEmail, setNewEmail] = useState('');
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    const [getLoading,setGetLoading] = useState(false)
    const handleInputChange = (event) => {
        setNewEmail(event.target.value);
    };

    const handleButtonClick = async () => {
        if (newEmail === '') {
            alert('Please enter a new email.');
            return;
        }
        setGetLoading(true)
        console.log(newEmail)
        const requestBody = {
            updateProfile: 'email',
            Value: newEmail,
        };

        try {
            const response = await fetch('https://laravel.cashflow365.se/api/update/profile?year=' + Year + '&comp_id=' + CompanyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                const data = await response.json();
                alert('Email updated successfully.');
                setGetLoading(false)
                setNewEmail('')
            } else {
                const errorData = await response.json();
                alert(`Error: ${errorData.message}`);
                setGetLoading(false)
                setNewEmail('')
            }
        } catch (error) {
            alert(`Error: ${error.message}`);
        }
    };
    if (getLoading) return <div className='loader-container'>
        <div class="loader"></div>
    </div>;

    return (
        <>
            <div className=' container mt-5'>
                <div className='FormContainer '>
                    <div className='row'>
                        <div className='MonthlyHeading p-2'>
                            <span className='MonthlyHeadingText'>Update Email</span>
                        </div>
                        <div className='col-lg-12'>
                            <div className='formHeadingSales'><label></label></div>
                            <div className='inputboxdiv'>
                                <input
                                    type='text'
                                    className='inputbox'
                                    placeholder='Enter New Email'
                                    value={newEmail}
                                    onChange={handleInputChange}
                                />
                                <input
                                    type='button'
                                    className='inputboxButton'
                                    value='Update'
                                    onClick={handleButtonClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateEmail;
