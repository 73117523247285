import React, { useState, useEffect } from 'react';
import Api from '../api/Api';
import CustomTable from './Table/Table';
import darkMode from '../assets/img/darkMode.png';
import '../assets/css/Table.css';
import '../assets/css/headerCustom.css';
const Year = localStorage.getItem("Year")

const month = ["12","1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]; 
const month2 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]; 
const month3= ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",]; 
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Sum"];
const Month = ["Dec","Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const Month22 = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const incomeStatementHead = ["Income Statement", ...months];
const balanceSheetHead = ["Balance Sheet", ...Month];
const cashFlowHead = ["Cash Flow", ...Month22];

export default function Report() {
    const [activeTable, setActiveTable] = useState('Income Statement');
    const [darkModeEnabled, setDarkModeEnabled] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [cash_equivalent_ope_bal, setcash_equivalent_ope_bal] = useState(null);
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');

    const { response: responseAutomation, loading: loadingAutomation, error: errorAutomation } = Api({
        modal: 'calculate_report',
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET'
    });
    useEffect(() => {
        if (loadingAutomation) {
            console.log('Loading report data...');
        }
        if (errorAutomation) {
            console.error('Error fetching report data:', errorAutomation.message);
        }
        if (responseAutomation) {
            console.log('Report data fetched successfully:', responseAutomation);
            setcash_equivalent_ope_bal(responseAutomation)
            setReportData(responseAutomation.variables); // Store formatted API response in state
        }
    }, [responseAutomation, loadingAutomation, errorAutomation]);

    const incomeStatementRows = reportData ? [
        ['Revenue', ...month2.map(month => (reportData.total_sale && reportData.total_sale[month]) ? reportData.total_sale[month] : '0'), calculateSum(reportData.total_sale)],
        ['Cost of Sold Goods', ...month2.map(month => (reportData.total_cost_sold_good && reportData.total_cost_sold_good[month]) ? reportData.total_cost_sold_good[month] : '0'), calculateSum(reportData.total_cost_sold_good)],
        ['Gross Profit', ...month2.map(month => (reportData.gross_profit && reportData.gross_profit[month]) ? reportData.gross_profit[month] : '0'), calculateSum(reportData.gross_profit)],
        ['Operating Expenses'],
        ['Salaries & Wages', ...month2.map(month => (reportData.salary_exp && reportData.salary_exp[month]) ? reportData.salary_exp[month] : '0'), calculateSum(reportData.salary_exp)],
        ['Other Operating Expenses', ...month2.map(month => (reportData.oth_opr_exp && reportData.oth_opr_exp[month]) ? reportData.oth_opr_exp[month] : '0'), calculateSum(reportData.oth_opr_exp)],
        ['Depreciation', ...month2.map(month => (reportData.equip_deprec && reportData.long_trm_deprec && reportData.equip_deprec[month] + reportData.long_trm_deprec[month]) ? (reportData.equip_deprec[month] + reportData.long_trm_deprec[month]) : '0'), calculateSum(reportData.equip_deprec) + calculateSum(reportData.long_trm_deprec)],
        ['Total Operating Expenses', ...month2.map(month => (reportData.total_opr_exp && reportData.total_opr_exp[month]) ? reportData.total_opr_exp[month] : '0'), calculateSum(reportData.total_opr_exp)],
        ['Interest Expenses', ...month2.map(month => (reportData.interest_rate && reportData.interest_rate[month]) ? reportData.interest_rate[month] : '0'), calculateSum(reportData.interest_rate)],
        ['Earning Before Tax', ...month2.map(month => (reportData.earning_before_tax && reportData.earning_before_tax[month]) ? reportData.earning_before_tax[month] : '0'), calculateSum(reportData.earning_before_tax)],
        ['Income Taxes', ...month2.map(month => (reportData.income_taxes && reportData.income_taxes[month]) ? reportData.income_taxes[month] : '0'), calculateSum(reportData.income_taxes)],
        ['Net Income', ...month2.map(month => (reportData.net_income && reportData.net_income[month]) ? reportData.net_income[month] : '0'), calculateSum(reportData.net_income)],
    ] : [];

    const balanceSheetRows = reportData ? [
        ['Assets'],
        ['Cash and Cash Equivalents',cash_equivalent_ope_bal.cash_equivalent_ope_bal, ...month2.map(month => (reportData.end_of_mont_bal && reportData.end_of_mont_bal[month]) ? reportData.end_of_mont_bal[month] : '0')],
        ['Account Receivable',cash_equivalent_ope_bal.acc_receiv_ope_bal, ...month2.map(month => (reportData.account_receivable && reportData.account_receivable[month]) ? reportData.account_receivable[month] : '0')],
        // ['Gross Profit', ...month.map(month => (reportData.gross_profit && reportData.gross_profit[month]) ? reportData.gross_profit[month] : '0'), calculateSum(reportData.gross_profit)],
        ['Inventories',cash_equivalent_ope_bal.inventory_beg_bal, ...month2.map(month => (reportData.inventory && reportData.inventory[month]) ? reportData.inventory[month] : '0')],
        ['Other Current Assets',cash_equivalent_ope_bal.othr_curr_ass_ope_bal, ...month2.map(month => (reportData.other_curr_assets && reportData.other_curr_assets[month]) ? reportData.other_curr_assets[month] : '0')],
        ['Total Current Assets',(cash_equivalent_ope_bal.cash_equivalent_ope_bal+cash_equivalent_ope_bal.acc_receiv_ope_bal+cash_equivalent_ope_bal.inventory_beg_bal+cash_equivalent_ope_bal.othr_curr_ass_ope_bal), ...month2.map(month => (reportData.total_curr_assets && reportData.end_of_mont_bal && reportData.total_curr_assets[month] + reportData.end_of_mont_bal[month]) ? (reportData.total_curr_assets[month] + reportData.end_of_mont_bal[month]) : '0')],
        ['Fixed Assets'],
        ['Property, Plant & Equipment',cash_equivalent_ope_bal.prop_plant_ope_bal, ...month2.map(month => (reportData.equip_end_bal && reportData.equip_end_bal[month]) ? reportData.equip_end_bal[month] : '0')],
        ['Long-Term Investments',cash_equivalent_ope_bal.long_term_invest_bal, ...month2.map(month => (reportData.long_invest_end_bal && reportData.long_invest_end_bal[month]) ? reportData.long_invest_end_bal[month] : '0')],
        ['Total Fixed Assets',(cash_equivalent_ope_bal.prop_plant_ope_bal+cash_equivalent_ope_bal.long_term_invest_bal), ...month2.map(month => (reportData.total_fixed_asset && reportData.total_fixed_asset[month]) ? reportData.total_fixed_asset[month] : '0')],
        ['Total Assets',(cash_equivalent_ope_bal.cash_equivalent_ope_bal+cash_equivalent_ope_bal.acc_receiv_ope_bal+cash_equivalent_ope_bal.inventory_beg_bal+cash_equivalent_ope_bal.othr_curr_ass_ope_bal+cash_equivalent_ope_bal.prop_plant_ope_bal+cash_equivalent_ope_bal.long_term_invest_bal),...month2.map(month => (reportData.total_new_assets && reportData.total_new_assets[month]) ? reportData.total_new_assets[month] : '0')],
        ['Liabilities'],
        ['Account Payable',cash_equivalent_ope_bal.acc_pay_ope_bal, ...month2.map(month => (reportData.total_acc_pay && reportData.total_acc_pay[month]) ? reportData.total_acc_pay[month] : '0')], 
        ['Accrued Expenses',cash_equivalent_ope_bal.accrued_exp_ope_bal, ...month2.map(month => (reportData.total_acc_exp && reportData.total_acc_exp[month]) ? reportData.total_acc_exp[month] : '0')],
        ['Short-Term Debt',cash_equivalent_ope_bal.short_open_bal, ...month2.map(month => (reportData.short_end_bal && reportData.short_end_bal[month]) ? reportData.short_end_bal[month] : '0')],
        ['Total Current Liabilities',(cash_equivalent_ope_bal.acc_pay_ope_bal+cash_equivalent_ope_bal.accrued_exp_ope_bal+cash_equivalent_ope_bal.short_open_bal), ...month2.map(month => (reportData.total_curr_liabilities && reportData.total_curr_liabilities[month]) ? reportData.total_curr_liabilities[month] : '0')],
        ['Long -Term Liabilities'],
        ['Long -Term Debt',cash_equivalent_ope_bal.long_open_bal, ...month2.map(month => (reportData.long_end_bal && reportData.long_end_bal[month]) ? reportData.long_end_bal[month] : '0')],
        ['Total Long-Term Liabilities',cash_equivalent_ope_bal.long_open_bal, ...month2.map(month => (reportData.total_long_term_liabilities && reportData.total_long_term_liabilities[month]) ? reportData.total_long_term_liabilities[month] : '0')],
        ['Owner´s Equity'],
        ['Owner´s Investment',cash_equivalent_ope_bal.owner_invest_ope_bal, ...month2.map(month => (reportData.total_equity && reportData.total_equity[month]) ? reportData.total_equity[month] : '0')],
        ['Retained Earnings',cash_equivalent_ope_bal.retained_ope_bal, ...month2.map(month => (reportData.total_retained_earn && reportData.total_retained_earn[month]) ? reportData.total_retained_earn[month] : '0')],
        ['Net Income',cash_equivalent_ope_bal.net_income_ope_bal, ...month2.map(month => (reportData.net_income && reportData.net_income[month]) ? reportData.net_income[month] : '0')],
        ['Total Owner´s Equity',(cash_equivalent_ope_bal.owner_invest_ope_bal+cash_equivalent_ope_bal.retained_ope_bal+cash_equivalent_ope_bal.net_income_ope_bal), ...month2.map(month => (reportData.total_owners_equity && reportData.total_owners_equity[month]) ? reportData.total_owners_equity[month] : '0')],
        ['Total Liabilities & Equity',(cash_equivalent_ope_bal.acc_pay_ope_bal + cash_equivalent_ope_bal.accrued_exp_ope_bal + cash_equivalent_ope_bal.short_open_bal + cash_equivalent_ope_bal.long_open_bal + cash_equivalent_ope_bal.owner_invest_ope_bal + cash_equivalent_ope_bal.retained_ope_bal + cash_equivalent_ope_bal.net_income_ope_bal), ...month2.map(month => (reportData.total_new_liabilities && reportData.total_new_liabilities[month]) ? reportData.total_new_liabilities[month] : '0')],
    ] : [];
    const cashflow = reportData ? [
        ['Beginning Month Balance',cash_equivalent_ope_bal.cash_equivalent_ope_bal, ...month3.map(month => (reportData.end_of_mont_bal && reportData.end_of_mont_bal[month]) ? reportData.end_of_mont_bal[month] : '0')],
        ['Operations'],
        ['Cash Receipt from Customers', ...month2.map(month => (reportData.cash_rec_frm_cus && reportData.cash_rec_frm_cus[month]) ? reportData.cash_rec_frm_cus[month] : '0')],
        ['Cash Paid to Suppliers', ...month2.map(month => (reportData.cash_paid_supp && reportData.cash_paid_supp[month]) ? reportData.cash_paid_supp[month] : '0')],
        ['Salaries & Wages Expenses', ...month2.map(month => (reportData.salary_wages && reportData.salary_wages[month]) ? reportData.salary_wages[month] : '0')],
        ['Intrerest Expenses', ...month2.map(month => (reportData.interest_exp && reportData.interest_exp[month]) ? reportData.interest_exp[month] : '0')],
        ['Income Taxes', ...month2.map(month => (reportData.income_taxes && reportData.income_taxes[month]) ? reportData.income_taxes[month] : '0')],
        ['Change Other Current Assets', ...month2.map(month => (reportData.chng_othr_curr_asst && reportData.chng_othr_curr_asst[month]) ? reportData.chng_othr_curr_asst[month] : '0')],
        ['Change Accrued Expenses', ...month2.map(month => (reportData.chng_acc_exp && reportData.chng_acc_exp[month]) ? reportData.chng_acc_exp[month] : '0')],
        ['Net Cash Flow from Operations', ...month2.map(month => (reportData.net_cash_flow && reportData.net_cash_flow[month]) ? reportData.net_cash_flow[month] : '0')],
        ['Investing Activities'],
        ['Purchase of Equipment', ...month2.map(month => (reportData.purc_of_equip && reportData.purc_of_equip[month]) ? reportData.purc_of_equip[month] : '0')],
        ['Sale of Equipment', ...month2.map(month => (reportData.sale_of_equip && reportData.sale_of_equip[month]) ? reportData.sale_of_equip[month] : '0')],
        ['Purchase of Investments', ...month2.map(month => (reportData.purc_of_invest && reportData.purc_of_invest[month]) ? reportData.purc_of_invest[month] : '0')],
        ['Sale of Investments', ...month2.map(month => (reportData.sales_of_invest && reportData.sales_of_invest[month]) ? reportData.sales_of_invest[month] : '0')],
        ['Net cash Flow from Investing', ...month2.map(month => (reportData.net_cash_flow_invest && reportData.net_cash_flow_invest[month]) ? reportData.net_cash_flow_invest[month] : '0')], 
        ['Financing Activities'],
        ['Loans Raised', ...month2.map(month => (reportData.loan_raised && reportData.loan_raised[month]) ? reportData.loan_raised[month] : '0')],
        ['Repayment of Debt', ...month2.map(month => (reportData.repayment_debt && reportData.repayment_debt[month]) ? reportData.repayment_debt[month] : '0')],
        ['Paid-in Capital', ...month2.map(month => (reportData.paid_in_capit && reportData.paid_in_capit[month]) ? reportData.paid_in_capit[month] : '0')],
        ['Dividends Paid', ...month2.map(month => (reportData.divid_paid && reportData.divid_paid[month]) ? reportData.divid_paid[month] : '0')],
        ['Net cash Flow from Financing', ...month2.map(month => (reportData.net_cash_flow_financ && reportData.net_cash_flow_financ[month]) ? reportData.net_cash_flow_financ[month] : '0')],
        ['End of Month Balance', ...month2.map(month => (reportData.end_of_mont_bal && reportData.end_of_mont_bal[month]) ? reportData.end_of_mont_bal[month] : '0')],
    ] : [];

    function calculateSum(data) {
        if (!data) return '0';
        return Object.values(data).reduce((acc, val) => acc + parseFloat(val || 0), 0);
    }

    const handleTableChange = (tableName) => {
        setActiveTable(tableName);
    };

    const handleDarkModeToggle = () => {
        setDarkModeEnabled(!darkModeEnabled);
        if (!darkModeEnabled) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    };

    const renderTable = () => {
        switch (activeTable) {
            case 'Income Statement':
                return <CustomTable head={incomeStatementHead} rows={incomeStatementRows} />;
            case 'Balance Sheet':
                return <CustomTable head={balanceSheetHead} rows={balanceSheetRows} />;
            case 'Cash Flow':
                return <CustomTable head={cashFlowHead} rows={cashflow} />; 
            default:
                return null;
        }
    };

    return (
        <>
            <div className=' mb-5 text-center mt-5'>
                <div className='containerReportBox p-2 position-fixed bgcolor buttonBox'>
                    <div className="row p-2  ">
                        <div className='col-lg-12 d-flex buttonReportDiv'>
                            <div className={`buttonReports text-center ${activeTable === 'Income Statement' ? 'isActive' : ''}`} onClick={() => handleTableChange('Income Statement')}>Income Statement</div>
                            <div className={`buttonReports text-center ${activeTable === 'Balance Sheet' ? 'isActive' : ''}`} onClick={() => handleTableChange('Balance Sheet')}>Balance sheet</div>
                            <div className={`buttonReports text-center ${activeTable === 'Cash Flow' ? 'isActive' : ''}`} onClick={() => handleTableChange('Cash Flow')}>Cash Flow</div>
                        </div>
                        {/* <div className='col-lg-5'>
                            <div className='float-end'>
                                <img src={darkMode} alt="darkModeIcon" className='reportIcon' onClick={handleDarkModeToggle} />
                            </div>
                        </div> */}
                    </div>
                </div>  
            </div>
            <div className='container' >
                <div className='row'>
                    <div className='col-lg-12 mt-2'>
                        {renderTable()}
                    </div>
                </div>
            </div>
        </>
    );
}
