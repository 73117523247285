import React, { useEffect, useState } from 'react';

const ReportComponent = () => {
    const [Data, setData] = useState(null);
    const [balanceSheet, setbalanceSheet] = useState(null);
    const [incomeSheet, setincomeSheet] = useState(null);
    const [cashFlowSheet, setcashFlowSheet] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://laravel.cashflow365.se/api/calculate_report?year=2023&comp_id=demo1');
                const data = await response.json();
                const calculatedData = calculateReport(data.variables, data);
                setData(calculatedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const calculateReport = (data, dataCash) => {
        const result = {
            // income statement
            Revenue: '', Cost_of_Sold_Goods: '', Gross_Profit: '',
            Salaries_Wages: '', Other_Operating_Expenses: '',
            Depreciation: '', Total_Operating_Expenses: '', Interest_Expenses: '',
            Earning_Before_Tax: '', Income_Taxes: '', Net_Income: '',
            // balance sheet
            Cash_and_Cash_Equivalents: '', Account_receivable: '', Inventories: '',
            Other_Current_Assets: '', Total_Current_Assets: '',
            Property_Plant_Equipment: '', Long_term_investments: '',
            Total_Fixed_Assets: '', TOTAL_ASSETS: '',
            Account_Payable: '', Accrued_Expenses: '', Short_term_Debt: '',
            Total_Current_Liabilities: '', Long_Term_Debt: '',
            Total_Long_Term_Liabilities: '', Owner_Investment: '',
            Retained_earnings: '', Total_Owner_Equity: '',
            TOTAL_LIABILITIES_EQUITY: '',
            // cash flow
            Beginning_Month_Balance: '', Cash_receipt_from_customers: '',
            Cash_paid_to_suppliers: '', Salaries_Wages_expenses: '',
            Interest_Expenses: '', Income_Taxes_cashflow: '',
            Change_Other_Current_Assets: '', Change_Accrued_Expenses: '',
            Net_cash_Flow_from_Operations: '', Purchase_of_Equipment: '',
            Sale_of_Equipment: '', Purchase_of_Investments: '',
            Sale_of_Investments: '', Net_cash_Flow_from_Investing: '',
            Loans_raised: '', Repayment_of_debt: '', Paid_in_Capital: '',
            Dividends_paid: '', Net_cash_Flow_from_Financing: '',
            End_of_Month_Balance: ''
        };

        for (let i = 1; i <= 12; i++) {
            result.Revenue += `${i === 1 ? '' : ';'}${data.total_sale[i]}`;
            result.Cost_of_Sold_Goods += `${i === 1 ? '' : ';'}${data.total_cost_sold_good[i]}`;
            result.Gross_Profit += `${i === 1 ? '' : ';'}${data.gross_profit[i]}`;
            result.Salaries_Wages += `${i === 1 ? '' : ';'}${data.salary_exp[i]}`;
            result.Other_Operating_Expenses += `${i === 1 ? '' : ';'}${data.oth_opr_exp[i]}`;
            result.Depreciation += `${i === 1 ? '' : ';'}${data.equip_deprec[i] + data.long_trm_deprec[i]}`;
            result.Total_Operating_Expenses += `${i === 1 ? '' : ';'}${data.total_opr_exp[i]}`;
            result.Interest_Expenses += `${i === 1 ? '' : ';'}${data.int_rate[i]}`;
            result.Earning_Before_Tax += `${i === 1 ? '' : ';'}${data.earning_before_tax[i]}`;
            result.Income_Taxes += `${i === 1 ? '' : ';'}${data.income_tax[i]}`;
            result.Net_Income += `${i === 1 ? '' : ';'}${data.net_income ? data.net_income[i] : 0}`;  // Adjust as per data structure
        }

        for (let i = 0; i <= 12; i++) {
            if (i === 0) {
                result.Cash_and_Cash_Equivalents = dataCash.cash_equivalent_ope_bal;
                result.Account_receivable = dataCash.acc_receiv_ope_bal;
                result.Inventories = dataCash.inventory_beg_bal;
                result.Other_Current_Assets = dataCash.othr_curr_ass_ope_bal;
                result.Total_Current_Assets = dataCash.othr_curr_ass_ope_bal + dataCash.inventory_beg_bal + dataCash.acc_receiv_ope_bal + dataCash.cash_equivalent_ope_bal;
                result.Property_Plant_Equipment = dataCash.equip_open_bal;
                result.Long_term_investments = dataCash.long_term_invest_bal;
                result.Total_Fixed_Assets = dataCash.long_term_invest_bal + dataCash.equip_open_bal;
                result.TOTAL_ASSETS = dataCash.long_term_invest_bal + dataCash.equip_open_bal + dataCash.othr_curr_ass_ope_bal + dataCash.inventory_beg_bal + dataCash.acc_receiv_ope_bal;
                result.Account_Payable = dataCash.total_acc_pay;
                result.Accrued_Expenses = dataCash.accrued_exp_ope_bal;
                result.Short_term_Debt = dataCash.short_open_bal;
                result.Total_Current_Liabilities = dataCash.short_open_bal + dataCash.accrued_exp_ope_bal + dataCash.accrued_exp_ope_bal;
                result.Long_Term_Debt = dataCash.long_open_bal;
                result.Total_Long_Term_Liabilities = dataCash.long_open_bal;
                result.Owner_Investment = dataCash.owner_invest_ope_bal;
                result.Retained_earnings = dataCash.retained_ope_bal;
                result.Net_Income = dataCash.net_income_ope_bal
                result.Total_Owner_Equity = dataCash.net_income_ope_bal + dataCash.retained_ope_bal + dataCash.owner_invest_ope_bal;
                result.TOTAL_LIABILITIES_EQUITY = dataCash.long_open_bal + dataCash.short_open_bal + dataCash.accrued_exp_ope_bal + dataCash.total_acc_pay + dataCash.net_income_ope_bal + dataCash.retained_ope_bal + dataCash.owner_invest_ope_bal;
            } else {
                result.Cash_and_Cash_Equivalents += `;${data.end_of_mont_bal[i]}`;
                result.Account_receivable += `;${data.account_receivable[i]}`;
                result.Inventories += `;${data.inventory[i]}`;
                result.Other_Current_Assets += `;${data.other_curr_assets[i]}`;
                result.Total_Current_Assets += `;${data.end_of_mont_bal[i] + data.account_receivable[i] + data.inventory[i] + data.other_curr_assets[i]}`;
                result.Property_Plant_Equipment += `;${data.equip_end_bal[i]}`;
                result.Long_term_investments += `;${data.long_invest_end_bal[i]}`;
                result.Total_Fixed_Assets += `;${data.total_fix_assets_sum[i]}`;
                result.TOTAL_ASSETS += `;${data.total_fix_assets_sum[i]}`;
                result.Account_Payable += `;${data.total_acc_exp[i]}`;
                result.Accrued_Expenses += `;${data.total_acc_exp[i]}`;
                result.Short_term_Debt += `;${data.short_end_bal[i]}`;
                result.Total_Current_Liabilities += `;${data.short_end_bal[i] + data.total_acc_exp[i]}`;
                result.Long_Term_Debt += `;${data.long_end_bal[i]}`;
                result.Total_Long_Term_Liabilities += `;${data.long_end_bal[i]}`;
                result.Owner_Investment += `;${data.paid_cap[i]}`;
                result.Retained_earnings += `;${data.div_paid[i]}`;
                result.Total_Owner_Equity += `;${data.total_liability_equity[i]}`;
                result.TOTAL_LIABILITIES_EQUITY += `;${data.total_liability_equity[i]}`;
            }
        }

        for (let i = 1; i <= 12; i++) {
            if (i === 1) {
                result.Beginning_Month_Balance = data.end_of_mont_bal[i];
                result.Cash_receipt_from_customers = data.total_sale[i];
                result.Cash_paid_to_suppliers = data.purchase_value[i];
                result.Salaries_Wages_expenses = data.salary_exp[i];
                result.Interest_Expenses = data.int_rate[i];
                result.Income_Taxes_cashflow = data.income_tax[i];
                result.Change_Other_Current_Assets = data.other_curr_assets[i];
                result.Change_Accrued_Expenses = data.total_acc_exp[i];
                result.Net_cash_Flow_from_Operations = data.net_cash_flow[i];
                result.Purchase_of_Equipment = data.pur_of_prop[i];
                result.Sale_of_Equipment = data.sales_equip[i];
                result.Purchase_of_Investments = data.pur_of_invest[i];
                result.Sale_of_Investments = data.sales_of_invest[i];
                result.Net_cash_Flow_from_Investing = data.net_cash_flow_invest[i];
                result.Loans_raised = data.short_loan_raised[i];
                result.Repayment_of_debt = data.short_repay_debt[i];
                result.Paid_in_Capital = data.paid_cap[i];
                result.Dividends_paid = data.div_paid[i];
                result.Net_cash_Flow_from_Financing = data.net_cash_flow_financ ? data.net_cash_flow_financ[i] : 0;  // Adjust as per data structure
                result.End_of_Month_Balance = data.end_of_mont_bal[i];
            } else {
                const m = i - 1;
                result.Beginning_Month_Balance += `;${data.end_of_mont_bal[m]}`;
                result.Cash_receipt_from_customers += `;${data.total_sale[i]}`;
                result.Cash_paid_to_suppliers += `;${data.purchase_value[i]}`;
                result.Salaries_Wages_expenses += `;${data.salary_exp[i]}`;
                result.Interest_Expenses += `;${data.int_rate[i]}`;
                result.Income_Taxes_cashflow += `;${data.income_tax[i]}`;
                result.Change_Other_Current_Assets += `;${data.other_curr_assets[i]}`;
                result.Change_Accrued_Expenses += `;${data.total_acc_exp[i]}`;
                result.Net_cash_Flow_from_Operations += `;${data.net_cash_flow[i]}`;
                result.Purchase_of_Equipment += `;${data.pur_of_prop[i]}`;
                result.Sale_of_Equipment += `;${data.sales_equip[i]}`;
                result.Purchase_of_Investments += `;${data.pur_of_invest[i]}`;
                result.Sale_of_Investments += `;${data.sales_of_invest[i]}`;
                result.Net_cash_Flow_from_Investing += `;${data.net_cash_flow_invest[i]}`;
                result.Loans_raised += `;${data.short_loan_raised[i]}`;
                result.Repayment_of_debt += `;${data.short_repay_debt[i]}`;
                result.Paid_in_Capital += `;${data.paid_cap[i]}`;
                result.Dividends_paid += `;${data.div_paid[i]}`;
                result.Net_cash_Flow_from_Financing += `;${data.net_cash_flow_financ ? data.net_cash_flow_financ[i] : 0}`;  // Adjust as per data structure
                result.End_of_Month_Balance += `;${data.end_of_mont_bal[i]}`;
            }
        }
        var balance_sheet_result_new;
        var income_statement_result_new;
        var cashflow_result_new;
        var summary_result_new;
        //income statement
        const Revenue = result.Revenue;
        const Cost_of_Sold_Goods = result.Cost_of_Sold_Goods;
        const Gross_Profit = result.Gross_Profit;
        const Salaries_Wages = result.Salaries_Wages;
        const Other_Operating_Expenses = result.Other_Operating_Expenses;
        const Depreciation = result.Depreciation;
        const Total_Operating_Expenses = result.Total_Operating_Expenses;
        const Interest_Expenses = result.Interest_Expenses;
        const Earning_Before_Tax = result.Earning_Before_Tax;
        const Income_Taxes = result.Income_Taxes;
        const Net_Income = result.Net_Income;
        //balance sheet
        const Cash_and_Cash_Equivalents = result.Cash_and_Cash_Equivalents;
        const Account_receivable = result.Account_receivable;
        const Inventories = result.Inventories;
        const Other_Current_Assets = result.Other_Current_Assets;
        const Total_Current_Assets = result.Total_Current_Assets;
        const Property_Plant_Equipment = result.Property_Plant_Equipment;
        const Long_term_investments = result.Long_term_investments;
        const Total_Fixed_Assets = result.Total_Fixed_Assets;
        const TOTAL_ASSETS = result.TOTAL_ASSETS;
        const Account_Payable = result.Account_Payable;
        const Accrued_Expenses = result.Accrued_Expenses;
        const Short_term_Debt = result.Short_term_Debt;
        const Total_Current_Liabilities = result.Total_Current_Liabilities;
        const Long_Term_Debt = result.Long_Term_Debt;
        const Total_Long_Term_Liabilities = result.Total_Long_Term_Liabilities;
        const Owner_Investment = result.Owner_Investment;
        const Retained_earnings = result.Retained_earnings;
        const Net_Income_balancesheet = result.Net_Income;
        const Total_Owner_Equity = result.Total_Owner_Equity;
        const TOTAL_LIABILITIES_EQUITY = result.TOTAL_LIABILITIES_EQUITY;
        //cash flow
        const Beginning_Month_Balance = result.Beginning_Month_Balance;
        const Cash_receipt_from_customers = result.Cash_receipt_from_customers;
        const Cash_paid_to_suppliers = result.Cash_paid_to_suppliers;
        const Salaries_Wages_expenses = result.Salaries_Wages_expenses;
        const Intrerest_Expenses = result.Interest_Expenses;
        const Income_Taxes_cashflow = result.Income_Taxes_cashflow;
        const Change_Other_Current_Assets = result.Change_Other_Current_Assets;
        const Change_Accrued_Expenses = result.Change_Accrued_Expenses;
        const Net_cash_Flow_from_Operations =
            result.Net_cash_Flow_from_Operations;
        const Purchase_of_Equipment = result.Purchase_of_Equipment;
        const Sale_of_Equipment = result.Sale_of_Equipment;
        const Purchase_of_Investments = result.Purchase_of_Investments;
        const Sale_of_Investments = result.Sale_of_Investments;
        const Net_cash_Flow_from_Investing = result.Net_cash_Flow_from_Investing;
        const Loans_raised = result.Loans_raised;
        const Repayment_of_debt = result.Repayment_of_debt;
        const Paid_in_Capital = result.Paid_in_Capital;
        const Dividends_paid = result.Dividends_paid;
        const Net_cash_Flow_from_Financing = result.Net_cash_Flow_from_Financing;
        const End_of_Month_Balance = result.End_of_Month_Balance;

        //   //Balance Sheet
        var cash_equi = Cash_and_Cash_Equivalents.split(";");
        var acc_rec = Account_receivable.split(";");
        var Invent = Inventories.split(";");
        var Oth_Curr_Ass = Other_Current_Assets.split(";");
        var Total_Curr_Ass = Total_Current_Assets.split(";");
        var Property_Plant_Equip = Property_Plant_Equipment.split(";");
        var Long_term_invest = Long_term_investments.split(";");
        var Total_Fixed_Ass = Total_Fixed_Assets.split(";");
        var TOTAL_ASS = TOTAL_ASSETS.split(";");
        var Account_Payabl = Account_Payable.split(";");
        var Accrued_Expense = Accrued_Expenses.split(";");
        var Short_term_Deb = Short_term_Debt.split(";");
        var Total_Current_Liabilitie = Total_Current_Liabilities.split(";");
        var Long_Term_Deb = Long_Term_Debt.split(";");
        var Total_Long_Term_Liabilitie = Total_Long_Term_Liabilities.split(";");
        var Owner_Investmen = Owner_Investment.split(";");
        var Retained_earning = Retained_earnings.split(";");
        var Net_income_balansheet = Net_Income_balancesheet
        var Total_Owner_Equit = Total_Owner_Equity.split(";");
        var TOTAL_LIABILITIES_EQUIT = TOTAL_LIABILITIES_EQUITY.split(";");

        // Income statement
        var Revenu = Revenue.split(";");
        var Cost_of_Sold_Good = Cost_of_Sold_Goods.split(";");
        var Gros_Profit = Gross_Profit.split(";");
        var Salaries_Wage = Salaries_Wages.split(";");
        var Other_Operating_Expense = Other_Operating_Expenses.split(";");
        var Depreciatio = Depreciation.split(";");
        var Total_Operating_Expense = Total_Operating_Expenses.split(";");
        var Interest_Expense = Interest_Expenses.split(";");
        var Earning_Befor_Tax = Earning_Before_Tax.split(";");
        var Incom_Taxes = Income_Taxes.split(";");
        var Net_Incom = Net_Income;

        // cash flow
        var Beginning_Month_Balanc = Beginning_Month_Balance.split(";");
        var Cash_receipt_from_customer = Cash_receipt_from_customers.split(";");
        var Cash_paid_to_supplier = Cash_paid_to_suppliers.split(";");
        var Salaries_Wages_expense = Salaries_Wages_expenses.split(";");
        var Intrerest_Expense = Intrerest_Expenses.split(";");
        var Income_Taxes_cashflo = Income_Taxes_cashflow.split(";");
        var Change_Other_Current_Asset = Change_Other_Current_Assets.split(";");
        var Change_Accrued_Expense = Change_Accrued_Expenses.split(";");
        var Net_cash_Flow_from_Operation =
            Net_cash_Flow_from_Operations.split(";");
        var Purchase_of_Equipmen = Purchase_of_Equipment.split(";");
        var Sale_of_Equipmen = Sale_of_Equipment.split(";");
        var Purchase_of_Investment = Purchase_of_Investments.split(";");
        var Sale_of_Investment = Sale_of_Investments.split(";");
        var Net_cash_Flow_from_Investin =
            Net_cash_Flow_from_Investing.split(";");
        var Loans_raise = Loans_raised.split(";");
        var Repayment_of_deb = Repayment_of_debt.split(";");
        var Paid_in_Capita = Paid_in_Capital.split(";");
        var Dividends_pai = Dividends_paid.split(";");
        var Net_cash_Flow_from_Financin =
            Net_cash_Flow_from_Financing.split(";");
        var End_of_Month_Balanc = End_of_Month_Balance.split(";");

       

        balance_sheet_result_new = {
            "Dec (föregående år)": {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[0]),
                        Kundfordringar: parseInt(acc_rec[0]),
                        Lager: parseInt(Invent[0]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[0]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[0]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[0]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[0]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[0]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[0]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[0]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[0]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[0]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[0]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[0]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[0]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[0]),
                        "Balanserat resultat": parseInt(Retained_earning[0]),
                        "Periodens resultat": parseInt(Net_income_balansheet[0]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[0]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[0]
                    ),
                },
            },
            Jan: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[1]),
                        Kundfordringar: parseInt(acc_rec[1]),
                        Lager: parseInt(Invent[1]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[1]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[1]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[1]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[1]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[1]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[1]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[1]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[1]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[1]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[1]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[1]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[1]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[1]),
                        "Balanserat resultat": parseInt(Retained_earning[1]),
                        "Periodens resultat": parseInt(Net_income_balansheet[1]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[1]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[1]
                    ),
                },
            },
            Feb: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[2]),
                        Kundfordringar: parseInt(acc_rec[2]),
                        Lager: parseInt(Invent[2]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[2]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[2]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[2]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[2]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[2]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[2]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[2]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[2]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[2]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[2]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[2]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[2]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[2]),
                        "Balanserat resultat": parseInt(Retained_earning[2]),
                        "Periodens resultat": parseInt(Net_income_balansheet[2]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[2]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[2]
                    ),
                },
            },
            Mar: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[3]),
                        Kundfordringar: parseInt(acc_rec[3]),
                        Lager: parseInt(Invent[3]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[3]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[3]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[3]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[3]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[3]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[3]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[3]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[3]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[3]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[3]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[3]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[3]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[3]),
                        "Balanserat resultat": parseInt(Retained_earning[3]),
                        "Periodens resultat": parseInt(Net_income_balansheet[3]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[3]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[3]
                    ),
                },
            },
            Apr: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[4]),
                        Kundfordringar: parseInt(acc_rec[4]),
                        Lager: parseInt(Invent[4]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[4]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[4]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[4]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[4]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[4]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[4]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[4]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[4]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[4]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[4]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[4]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[4]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[4]),
                        "Balanserat resultat": parseInt(Retained_earning[4]),
                        "Periodens resultat": parseInt(Net_income_balansheet[4]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[4]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[4]
                    ),
                },
            },
            Maj: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[5]),
                        Kundfordringar: parseInt(acc_rec[5]),
                        Lager: parseInt(Invent[5]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[5]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[5]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[5]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[5]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[5]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[5]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[5]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[5]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[5]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[5]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[5]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[5]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[5]),
                        "Balanserat resultat": parseInt(Retained_earning[5]),
                        "Periodens resultat": parseInt(Net_income_balansheet[5]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[5]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[5]
                    ),
                },
            },
            Jun: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[6]),
                        Kundfordringar: parseInt(acc_rec[6]),
                        Lager: parseInt(Invent[6]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[6]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[6]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[6]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[6]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[6]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[6]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[6]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[6]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[6]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[6]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[6]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[6]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[6]),
                        "Balanserat resultat": parseInt(Retained_earning[6]),
                        "Periodens resultat": parseInt(Net_income_balansheet[6]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[6]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[6]
                    ),
                },
            },
            Jul: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[7]),
                        Kundfordringar: parseInt(acc_rec[7]),
                        Lager: parseInt(Invent[7]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[7]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[7]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[7]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[7]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[7]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[7]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[7]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[7]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[7]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[7]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[7]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[7]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[7]),
                        "Balanserat resultat": parseInt(Retained_earning[7]),
                        "Periodens resultat": parseInt(Net_income_balansheet[7]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[7]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[7]
                    ),
                },
            },
            Aug: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[8]),
                        Kundfordringar: parseInt(acc_rec[8]),
                        Lager: parseInt(Invent[8]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[8]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[8]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[8]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[8]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[8]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[8]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[8]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[8]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[8]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[8]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[8]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[8]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[8]),
                        "Balanserat resultat": parseInt(Retained_earning[8]),
                        "Periodens resultat": parseInt(Net_income_balansheet[8]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[8]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[8]
                    ),
                },
            },
            Sep: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[9]),
                        Kundfordringar: parseInt(acc_rec[9]),
                        Lager: parseInt(Invent[9]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[9]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[9]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[9]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[9]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[9]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[9]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[9]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[9]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[9]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[9]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[9]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[9]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[9]),
                        "Balanserat resultat": parseInt(Retained_earning[9]),
                        "Periodens resultat": parseInt(Net_income_balansheet[9]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[9]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[9]
                    ),
                },
            },
            Okt: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[10]),
                        Kundfordringar: parseInt(acc_rec[10]),
                        Lager: parseInt(Invent[10]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[10]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[10]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[10]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[10]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[10]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[10]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[10]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[10]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[10]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[10]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[10]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[10]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[10]),
                        "Balanserat resultat": parseInt(Retained_earning[10]),
                        "Periodens resultat": parseInt(Net_income_balansheet[10]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[10]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[10]
                    ),
                },
            },
            Nov: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[11]),
                        Kundfordringar: parseInt(acc_rec[11]),
                        Lager: parseInt(Invent[11]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[11]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[11]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[11]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[11]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[11]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[11]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[11]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[11]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[11]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[11]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[11]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[11]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[11]),
                        "Balanserat resultat": parseInt(Retained_earning[11]),
                        "Periodens resultat": parseInt(Net_income_balansheet[11]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[11]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[11]
                    ),
                },
            },
            Dec: {
                Tillgångar: {
                    Omsättningstillgångar: {
                        "Kassa och bank": parseInt(cash_equi[12]),
                        Kundfordringar: parseInt(acc_rec[12]),
                        Lager: parseInt(Invent[12]),
                        "Övriga fordringar": parseInt(Oth_Curr_Ass[12]),
                        "Summa omsättningstillgångar": parseInt(Total_Curr_Ass[12]),
                    },
                    Anläggningstillgångar: {
                        "Maskiner och inventarier": parseInt(Property_Plant_Equip[12]),
                        "Långfristiga investeringar": parseInt(Long_term_invest[12]),
                        "Summa anläggningstillgångar": parseInt(Total_Fixed_Ass[12]),
                    },
                    "Summa tillgångar": parseInt(TOTAL_ASS[12]),
                },
                "Skulder och eget kapital": {
                    "Kortfristiga skulder": {
                        Leverantörsskulder: parseInt(Account_Payabl[12]),
                        "Upplupna kostnader": parseInt(Accrued_Expense[12]),
                        "Övriga Kortfristiga skulder": parseInt(Short_term_Deb[12]),
                        "Summa kortfristiga skulder": parseInt(
                            Total_Current_Liabilitie[12]
                        ),
                    },
                    "Långfristiga skulder": {
                        "Långfristig skuld": parseInt(Long_Term_Deb[12]),
                        "Summa långfristiga skulder": parseInt(
                            Total_Long_Term_Liabilitie[12]
                        ),
                    },
                    "Eget kapital": {
                        "Eget kapital": parseInt(Owner_Investmen[12]),
                        "Balanserat resultat": parseInt(Retained_earning[12]),
                        "Periodens resultat": parseInt(Net_income_balansheet[12]),
                        "Summa eget kapital": parseInt(Total_Owner_Equit[12]),
                    },
                    "Summa skulder och eget kapital": parseInt(
                        TOTAL_LIABILITIES_EQUIT[12]
                    ),
                },
            },
        };
        income_statement_result_new = {
            Jan: {
                Nettoomsättning: parseInt(Revenu[0]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[0]),
                Bruttoresultat: parseInt(Gros_Profit[0]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[0]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[0]),
                    Avskrivningar: parseInt(Depreciatio[0]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[0]),
                    "Finansiella kostnader": parseInt(Interest_Expense[0]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[0]),
                    "Skatt på resultat": parseInt(Incom_Taxes[0]),
                    Nettoresultat: parseInt(Net_Incom[1]),
                },
            },
            Feb: {
                Nettoomsättning: parseInt(Revenu[1]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[1]),
                Bruttoresultat: parseInt(Gros_Profit[1]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[1]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[1]),
                    Avskrivningar: parseInt(Depreciatio[1]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[1]),
                    "Finansiella kostnader": parseInt(Interest_Expense[1]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[1]),
                    "Skatt på resultat": parseInt(Incom_Taxes[1]),
                    Nettoresultat: parseInt(Net_Incom[2]),
                },
            },
            Mar: {
                Nettoomsättning: parseInt(Revenu[2]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[2]),
                Bruttoresultat: parseInt(Gros_Profit[2]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[2]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[2]),
                    Avskrivningar: parseInt(Depreciatio[2]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[2]),
                    "Finansiella kostnader": parseInt(Interest_Expense[2]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[2]),
                    "Skatt på resultat": parseInt(Incom_Taxes[2]),
                    Nettoresultat: parseInt(Net_Incom[3]),
                },
            },
            Apr: {
                Nettoomsättning: parseInt(Revenu[3]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[3]),
                Bruttoresultat: parseInt(Gros_Profit[3]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[3]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[3]),
                    Avskrivningar: parseInt(Depreciatio[3]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[3]),
                    "Finansiella kostnader": parseInt(Interest_Expense[3]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[3]),
                    "Skatt på resultat": parseInt(Incom_Taxes[3]),
                    Nettoresultat: parseInt(Net_Incom[4]),
                },
            },
            Maj: {
                Nettoomsättning: parseInt(Revenu[4]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[4]),
                Bruttoresultat: parseInt(Gros_Profit[4]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[4]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[4]),
                    Avskrivningar: parseInt(Depreciatio[4]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[4]),
                    "Finansiella kostnader": parseInt(Interest_Expense[4]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[4]),
                    "Skatt på resultat": parseInt(Incom_Taxes[4]),
                    Nettoresultat: parseInt(Net_Incom[5]),
                },
            },
            Jun: {
                Nettoomsättning: parseInt(Revenu[5]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[5]),
                Bruttoresultat: parseInt(Gros_Profit[5]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[5]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[5]),
                    Avskrivningar: parseInt(Depreciatio[5]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[5]),
                    "Finansiella kostnader": parseInt(Interest_Expense[5]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[5]),
                    "Skatt på resultat": parseInt(Incom_Taxes[5]),
                    Nettoresultat: parseInt(Net_Incom[6]),
                },
            },
            Jul: {
                Nettoomsättning: parseInt(Revenu[6]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[6]),
                Bruttoresultat: parseInt(Gros_Profit[6]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[6]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[6]),
                    Avskrivningar: parseInt(Depreciatio[6]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[6]),
                    "Finansiella kostnader": parseInt(Interest_Expense[6]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[6]),
                    "Skatt på resultat": parseInt(Incom_Taxes[6]),
                    Nettoresultat: parseInt(Net_Incom[7]),
                },
            },
            Aug: {
                Nettoomsättning: parseInt(Revenu[7]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[7]),
                Bruttoresultat: parseInt(Gros_Profit[7]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[7]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[7]),
                    Avskrivningar: parseInt(Depreciatio[7]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[7]),
                    "Finansiella kostnader": parseInt(Interest_Expense[7]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[7]),
                    "Skatt på resultat": parseInt(Incom_Taxes[7]),
                    Nettoresultat: parseInt(Net_Incom[8]),
                },
            },
            Sep: {
                Nettoomsättning: parseInt(Revenu[8]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[8]),
                Bruttoresultat: parseInt(Gros_Profit[8]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[8]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[8]),
                    Avskrivningar: parseInt(Depreciatio[8]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[8]),
                    "Finansiella kostnader": parseInt(Interest_Expense[8]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[8]),
                    "Skatt på resultat": parseInt(Incom_Taxes[8]),
                    Nettoresultat: parseInt(Net_Incom[9]),
                },
            },
            Okt: {
                Nettoomsättning: parseInt(Revenu[9]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[9]),
                Bruttoresultat: parseInt(Gros_Profit[9]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[9]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[9]),
                    Avskrivningar: parseInt(Depreciatio[9]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[9]),
                    "Finansiella kostnader": parseInt(Interest_Expense[9]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[9]),
                    "Skatt på resultat": parseInt(Incom_Taxes[9]),
                    Nettoresultat: parseInt(Net_Incom[10]),
                },
            },
            Nov: {
                Nettoomsättning: parseInt(Revenu[10]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[10]),
                Bruttoresultat: parseInt(Gros_Profit[10]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[10]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[10]),
                    Avskrivningar: parseInt(Depreciatio[10]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[10]),
                    "Finansiella kostnader": parseInt(Interest_Expense[10]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[10]),
                    "Skatt på resultat": parseInt(Incom_Taxes[10]),
                    Nettoresultat: parseInt(Net_Incom[11]),
                },
            },
            Dec: {
                Nettoomsättning: parseInt(Revenu[11]),
                "Kostnad för sålda varor": parseInt(Cost_of_Sold_Good[11]),
                Bruttoresultat: parseInt(Gros_Profit[11]),
                Rörelsekostnader: {
                    Arbetskraftskostnader: parseInt(Salaries_Wage[11]),
                    "Övriga externa kostnader": parseInt(Other_Operating_Expense[11]),
                    Avskrivningar: parseInt(Depreciatio[11]),
                    "Summa rörelsekostnader": parseInt(Total_Operating_Expense[11]),
                    "Finansiella kostnader": parseInt(Interest_Expense[11]),
                    "Resultat före skatt": parseInt(Earning_Befor_Tax[11]),
                    "Skatt på resultat": parseInt(Incom_Taxes[11]),
                    Nettoresultat: parseInt(Net_Incom[12]),
                },
            },
        };

        cashflow_result_new = {
            Jan: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[0]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[0]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[0]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[0]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[0]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[0]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[0]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[0]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[0]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[0]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[0]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[0]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[0]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[0]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[0]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[0]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[0]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[0]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[0]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[0]),
            },
            Feb: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[1]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[1]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[1]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[1]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[1]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[1]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[1]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[1]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[1]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[1]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[1]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[1]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[1]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[1]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[1]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[1]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[1]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[1]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[1]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[1]),
            },
            Mar: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[2]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[2]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[2]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[2]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[2]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[2]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[2]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[2]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[2]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[2]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[2]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[2]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[2]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[2]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[2]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[2]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[2]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[2]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[2]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[2]),
            },
            Apr: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[3]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[3]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[3]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[3]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[3]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[3]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[3]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[3]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[3]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[3]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[3]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[3]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[3]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[3]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[3]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[3]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[3]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[3]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[3]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[3]),
            },
            Maj: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[4]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[4]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[4]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[4]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[4]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[4]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[4]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[4]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[4]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[4]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[4]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[4]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[4]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[4]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[4]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[4]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[4]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[4]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[4]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[4]),
            },
            Jun: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[5]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[5]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[5]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[5]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[5]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[5]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[5]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[5]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[5]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[5]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[5]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[5]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[5]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[5]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[5]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[5]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[5]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[5]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[5]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[5]),
            },
            Jul: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[6]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[6]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[6]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[6]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[6]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[6]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[6]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[6]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[6]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[6]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[6]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[6]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[6]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[6]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[6]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[6]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[6]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[6]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[6]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[6]),
            },
            Aug: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[7]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[7]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[7]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[7]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[7]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[7]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[7]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[7]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[7]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[7]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[7]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[7]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[7]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[7]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[7]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[7]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[7]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[7]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[7]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[7]),
            },
            Sep: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[8]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[8]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[8]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[8]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[8]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[8]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[8]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[8]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[8]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[8]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[8]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[8]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[8]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[8]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[8]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[8]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[8]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[8]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[8]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[8]),
            },
            Okt: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[9]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[9]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[9]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[9]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[9]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[9]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[9]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[9]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[9]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[9]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[9]
                    ),
                    "Inköp övriga investeringar": parseInt(Purchase_of_Investment[9]),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[9]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[9]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[9]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[9]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[9]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[9]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[9]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[9]),
            },
            Nov: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[10]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[10]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[10]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[10]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[10]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[10]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[10]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[10]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[10]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[10]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[10]
                    ),
                    "Inköp övriga investeringar": parseInt(
                        Purchase_of_Investment[10]
                    ),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[10]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[10]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[10]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[10]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[10]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[10]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[10]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[10]),
            },
            Dec: {
                "Ingående balans": parseInt(Beginning_Month_Balanc[11]),
                "Löpande verksamhet": {
                    "Inbetalningar från kunder": parseInt(
                        Cash_receipt_from_customer[11]
                    ),
                    "Utbetalningar till leverantörer": parseInt(
                        Cash_paid_to_supplier[11]
                    ),
                    Arbetskraftskostnader: parseInt(Salaries_Wages_expense[11]),
                    "Finansiella kostnader": parseInt(Intrerest_Expense[11]),
                    Inkomstskatt: parseInt(Income_Taxes_cashflo[11]),
                    "Förändringar kortfristiga fordringar": parseInt(
                        Change_Other_Current_Asset[11]
                    ),
                    "Förändring upplupna skulder": parseInt(
                        Change_Accrued_Expense[11]
                    ),
                    "Kassaflöde löpande verksamheten": parseInt(
                        Net_cash_Flow_from_Operation[11]
                    ),
                },
                Investeringar: {
                    "Inköp maskiner och inventarier": parseInt(
                        Purchase_of_Equipmen[11]
                    ),
                    "Försäljning maskiner och inventarier": parseInt(
                        Sale_of_Equipmen[11]
                    ),
                    "Inköp övriga investeringar": parseInt(
                        Purchase_of_Investment[11]
                    ),
                    "Försäljning övriga investeringar": parseInt(
                        Sale_of_Investment[11]
                    ),
                    "Kassaflöde investeringar": parseInt(
                        Net_cash_Flow_from_Investin[11]
                    ),
                },
                Finansiering: {
                    "Upptagna lån": parseInt(Loans_raise[11]),
                    "Amortering av skulder": parseInt(Repayment_of_deb[11]),
                    "Inbetalning från aktieägare": parseInt(Paid_in_Capita[11]),
                    "Utdelning till aktieägare": parseInt(Dividends_pai[11]),
                    "Kassaflöde finansiering": parseInt(
                        Net_cash_Flow_from_Financin[11]
                    ),
                },
                "Utgående balans": parseInt(End_of_Month_Balanc[11]),
            },
        };
        localStorage.setItem('balanceSheet', balance_sheet_result_new);
        localStorage.setItem('balanceRes', balance_sheet_result_new);
        localStorage.setItem('Cashflow', cashflow_result_new);
        setbalanceSheet(balance_sheet_result_new);
        setincomeSheet(income_statement_result_new);
        setcashFlowSheet(cashflow_result_new);
        return result;
    };



    return (
        <div className='bg-primary'>
         
        </div>
    );
};

export default ReportComponent;
