import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table';

const Ai = {
    monthlyHeadingText: 'Other Operating Expense Detail',
    formSections: [
        {
            heading: '',
            inputPlaceholder: 'Other Operating Expense Name',
            buttonText: 'Update',
            type:'text'  
        },
    ],
};

const head = [ 'Other Operating Expense', 'Created At', 'Updated At', ''];

function AddOtherOperatingExpense() {
    const [year, setYear] = useState(localStorage.getItem("Year"));
    const [getOtherOperatingExpense, setGetOtherOperatingExpense] = useState([]);
    const [newOtherOperatingExpense, setNewOtherOperatingExpense] = useState('');
    const [selectedOtherOperatingExpenseId, setSelectedOtherOperatingExpenseId] = useState(null);
    const [postError, setPostError] = useState(null);
    const [postLoading, setPostLoading] = useState(false);
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);

    const CompanyId = localStorage.getItem('CompanyId');

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "Year") {
                setYear(event.newValue);
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const fetchOtherOperatingExpenses = async () => {
        setGetLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/add_other_opr_exp?year=${year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }

            const formattedData = data.othr_opr_expense_details.map(item => ({
                ...item,
                created_at: formatDate(item.created_at),
                updated_at: formatDate(item.updated_at)
            }));

            setGetOtherOperatingExpense(formattedData);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading(false);
        }
    };

    useEffect(() => {
        fetchOtherOperatingExpenses();
    }, [year]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    };

    const rows = getOtherOperatingExpense
        .filter(OtherOperatingExpense => OtherOperatingExpense && OtherOperatingExpense.othr_opr_expense_name !== undefined)
        .map((OtherOperatingExpense, index) => ({
            id: index + 1,
            othr_opr_expense_name: OtherOperatingExpense.othr_opr_expense_name,
            created_at: OtherOperatingExpense.created_at,
            updated_at: OtherOperatingExpense.updated_at,
            button: <input type='button' className='inputboxChangeButton' onClick={() => handleButtonClick(OtherOperatingExpense.id, OtherOperatingExpense.othr_opr_expense_name)} value='Change' />
        }));

    const arrayOfArrays = rows.map(row => [
        // row.id,
        row.othr_opr_expense_name,
        row.created_at,
        row.updated_at,
        row.button
    ]);

    const handleButtonClick = async (id, name) => {
        setNewOtherOperatingExpense(name);
        setSelectedOtherOperatingExpenseId(id);
    };

    const handleUpdateButtonClick = async () => {
        if (!newOtherOperatingExpense.trim()) {
            return;
        }
        setPostLoading(true);

        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            let url;
            let options;

            if (selectedOtherOperatingExpenseId) {
                url = `${baseUrl}/update_other_opr_exp?year=${year}&comp_id=${CompanyId}&id=${selectedOtherOperatingExpenseId}`;
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ opr_expense_name: newOtherOperatingExpense, CompanyId })
                };
            } else {
                url = `${baseUrl}/create_other_opr_exp?year=${year}&comp_id=${CompanyId}`;
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ opr_expense_name: newOtherOperatingExpense, CompanyId })
                };
            }

            const response = await fetch(url, options);
            const result = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }

            setPostError(null);
            setNewOtherOperatingExpense('');
            setSelectedOtherOperatingExpenseId(null);
            fetchOtherOperatingExpenses();
        } catch (error) {
            setPostError(error.message);
        } finally {
            setPostLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setNewOtherOperatingExpense(e.target.value);
    };

    if (getLoading) return <div className='loader-container'>
    <div class="loader"></div>
</div>;
    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                OtherOperatingExpenseAndServicesLabel="OtherOperatingExpense and Services"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleUpdateButtonClick}
                                handleInputChange={handleInputChange}
                                inputValue={newOtherOperatingExpense}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <CustomTable head={head} rows={arrayOfArrays} />
                    </div>
                </div>
            </div>
            {/* {postLoading && <p>Updating...</p>}
            {postError && <p>Error: {postError}</p>} */}
        </>
    );
}

export default AddOtherOperatingExpense;
