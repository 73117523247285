import React, { useState } from 'react';
const Year = process.env.REACT_APP_API_BASE_Year;

const UpdatePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const CompanyId = localStorage.getItem('CompanyId');

  const [getLoading,setGetLoading] = useState(false)
  const handleInputChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleButtonClick = async () => {
    if (newPassword === '') {
      alert('Please enter a new password.');
      return;
    }
    setGetLoading(true)
    console.log(newPassword)
    const requestBody = {
      updateProfile: 'password',
      Value: newPassword,
    };

    try {
      const response = await fetch('https://laravel.cashflow365.se/api/update/profile?year='+Year+'&comp_id='+CompanyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        alert('Password updated successfully.');
        setGetLoading(false)
        setNewPassword('')
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
      setGetLoading(false)
    }
  };
  if (getLoading) return <div className='loader-container'>
  <div class="loader"></div>
</div>;

  return (
    <>
      <div className=' container mt-5'>
        <div className=' FormContainer'>
          <div className='row'>
            <div className='MonthlyHeading p-2'>
              <span className='MonthlyHeadingText'>Update Password</span>
            </div>
            <div className='col-lg-12'>
              <div className='formHeadingSales'><label></label></div>
              <div className='inputboxdiv'>
                <input
                  type='password'
                  className='inputbox'
                  placeholder='Enter New Password'
                  value={newPassword}
                  onChange={handleInputChange}
                />
                <input
                  type='button'
                  className='inputboxButton'
                  value='Update'
                  onClick={handleButtonClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
