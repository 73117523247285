import React from 'react';

function FormSection({ sections, productAndServicesLabel, selectPlaceholder, handleButtonClick, handleInputChange, handleSelect1Change, handleSelect2Change, inputValue }) {

    const handleSelect1ChangeInternal = (event, sectionIndex) => {
        const selectedOption = event.target.value;
        handleSelect1Change(selectedOption, sectionIndex);
    };

    const handleSelect2ChangeInternal = (event, sectionIndex) => {
        const selectedOption = event.target.value;
        handleSelect2Change(selectedOption, sectionIndex);
    };

    return (
        <div className='FormContainer'>
            {sections.map((section, index) => (
                <div className='container' key={index}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='formHeadingSales'><label>{section.heading}</label></div>
                            <div className='inputboxdiv mt-2'>
                                {section.selectOptions1 && (
                                    <select
                                        className='inputbox'
                                        onChange={(event) => handleSelect1ChangeInternal(event, index)}
                                    >
                                        <option value="">{selectPlaceholder}</option>
                                        {section.selectOptions1.map((option, idx) => (
                                            <option key={idx} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                )}
                                {section.selectOptions2 && (
                                    <select
                                        className='inputbox'
                                        onChange={(event) => handleSelect2ChangeInternal(event, index)}
                                    >
                                        <option value="">{selectPlaceholder}</option>
                                        {section.selectOptions2.map((option, idx) => (
                                            <option key={idx} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                )}
                                {section.inputPlaceholder && (
                                    <input
                                        type={section.type}
                                        className='inputbox'
                                        placeholder={section.inputPlaceholder}
                                        value={inputValue}
                                        onChange={(event) => handleInputChange(event, index)}
                                    />
                                )}
                                <input
                                    type='button'
                                    className='inputboxButton'
                                    value={section.buttonText}
                                    onClick={() => handleButtonClick(index)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FormSection;
