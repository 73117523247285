import React, { useState } from 'react';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import '../../assets/css/Table.css';

const download = require('../../assets/img/download.png');

const CustomTable = ({ head, rows }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDownloadClick = () => {
    setShowDropdown(!showDropdown);
  };

  const extractTextContent = (cell) => {
    if (React.isValidElement(cell)) {
      return cell.props.children || '';
    }
    return cell;
  };

  // Custom number formatting for thousands, lakhs, and crores
  const formatNumberWithCommas = (value) => {
    if (typeof value !== 'number') return value;

    let numStr = value.toString();
    const length = numStr.length;

    // Handle crores (more than 7 digits)
    // if (length > 7) {
    //   return numStr.replace(/\B(?=(\d{2})+(?!\d))/g, ',').replace(/(\d)(\d{2})(,)/g, '$1,$2$3');
    // }

    // Handle lakhs (more than 5 digits but less than or equal to 7)
    // if (length > 5) {
    //   return numStr.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
    // }

    // Handle thousands (less than or equal to 5 digits)
    return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const prepareDataForDownload = (rows) => {
    return rows.map(row => row.map(cell => {
      if (typeof cell === 'number') {
        return formatNumberWithCommas(cell); // Format number with commas
      }
      return extractTextContent(cell);
    }));
  };

  const handleDownloadAsPDF = () => {
    const doc = new jsPDF('l', 'px', 'a4', true);
    const processedRows = prepareDataForDownload(rows);
    doc.autoTable({
      head: [head],
      body: processedRows,
      // Ensure the font size and alignment are set for better readability
      styles: { fontSize: 10, valign: 'middle', halign: 'center' },
    });
    doc.save('table.pdf');
    setShowDropdown(false);
  };

  const handleDownloadAsExcel = () => {
    const wb = XLSX.utils.book_new();
    const processedRows = prepareDataForDownload(rows);
    const ws = XLSX.utils.aoa_to_sheet([head, ...processedRows]);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'table.xlsx');
    setShowDropdown(false);
  };

  return (
    <>
      <div className='float-end'>
      <div className="downloadIcon "  onClick={handleDownloadClick}  style={{ cursor: 'pointer', marginRight: '10px' }}></div>
        {showDropdown && (
          <div className='dropdown-menu-table' style={{ display: 'block', position: 'absolute', zIndex: 1000, marginRight: '100px' }}>
            <button className='dropdown-item' onClick={handleDownloadAsPDF}>Download as PDF</button>
            <button className='dropdown-item' onClick={handleDownloadAsExcel}>Download as Excel</button>
          </div>
        )}
      </div>
      <div className='table-Container' style={{ marginBottom: '100px' }}>
        <table
          id="custom-table"
          className="table table-hover table-bordered table-container"
          style={{ borderCollapse: 'collapse', borderSpacing: '0', borderRadius: '15px', overflow: 'hidden', backgroundColor: 'white' }}
        >
          <thead>
            <tr>
              {head.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="text-center"
                  style={{ color: '#114CB2', fontWeight: '500', backgroundColor: '#F6F6F6' }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{ textAlign: cellIndex === 0 ? 'left' : 'right' }}
                  >
                    {formatNumberWithCommas(cell)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

CustomTable.propTypes = {
  head: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]))).isRequired,
};

export default CustomTable;
