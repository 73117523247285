import Dashboard from "../components/Dashboard";
import Reports from "../components/Reports";
import AiReports from "../components/AiReports";
import SalesInventories from "../components/SalesInventories";
import OpertingExpenses from "../components/OpertingExpenses";
import OtherCurrentAssets from "../components/OtherCurrentAssets";
import Debts from "../components/Debts";
import AccruedExpenses from "../components/AccruedExpenses";
import Equity from "../components/Equity";
import IncomeTex from "../components/IncomeTex";
import AddAccruedExpenses from "../components/AddAccruedExpenses";
import AddOtherCurrentAssets from "../components/AddOtherCurrentAssets";
import AddSalariesWages from "../components/AddSalaries&Wages";
import AddProduct from "../components/AddProduct";
import AddOtherOperating from "../components/AddOtherOperating";
import AddOpeningBalance from "../components/AddOpeningBalance";
import FixedAssests from "../components/FixedAssets";

const navConfig = [
  { title: 'Dashboard', path: '/', component: Dashboard },
  { title: 'Reports', path: '/reports', component: Reports },
  { title: 'AI Reports', path: '/aireports', component: AiReports },
  { title: 'Sales Inventories', path: '/salesInventories', component: SalesInventories },
  { title: 'Operating Expenses', path: '/operatingExpenses', component: OpertingExpenses },
  { title: 'Fixed Assets', path: '/fixedAssests', component: FixedAssests },
  { title: 'Other Current Assets', path: '/otherCurrentAssets', component: OtherCurrentAssets },
  { title: 'Debts', path: '/debts', component: Debts },
  { title: 'Accrued Expenses', path: '/accruedExpenses', component: AccruedExpenses },
  { title: 'Equity', path: '/equity', component: Equity },
  { title: 'Income Tax', path: '/incomeTax', component: IncomeTex },
  { title: 'Add Accrued Expenses', path: '/addAccruedExpenses', component: AddAccruedExpenses },
  { title: 'Add Other Current Assets', path: '/addOtherCurrentAssets', component: AddOtherCurrentAssets },
  { title: 'Add Salaries & Wages', path: '/addSalaries&Wages', component: AddSalariesWages },
  { title: 'Add Product', path: '/addProduct', component: AddProduct },
  { title: 'Add Other Operating Expenses', path: '/addOtherOperating', component: AddOtherOperating },
  { title: 'Add Opening Balance', path: '/addOpeningBalance', component: AddOpeningBalance },
];

export default navConfig;
