// dataService.js

// Example of setting and getting data

let data = []; // Example data storage, can be an array, object, etc.

export const setYearData = (newData) => {
  data = newData; // Sets the data to a new value
};
export const getYearData = () => {
  return data; // Returns the current data
};
