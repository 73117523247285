import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection';
import Api from '../api/Api';
import CustomTable from './Table/Table'; // Import your CustomTable component
import download from '../assets/img/download.png';
import darkMode from '../assets/img/darkMode.png';

function Equity() {
  const Userid = localStorage.getItem('UserName');
  const CompanyId = localStorage.getItem('CompanyId');
  const [DropdownAutomationdata, setDropdownAutomationdata] = useState(null);
  const [equityData, setEquityData] = useState(null); // State to store API response
  const [inputValue, setInputValue] = useState('');
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
  const [selectedMonthValue, setSelectedMonthValue] = useState('');
  const [getLoading, setGetLoading] = useState(false);
  const [automationInputValue, setAutomationInputValue] = useState('');
  const [automationDropdown, setAutomationDropdown] = useState('');
  const [year, setYear] = useState(localStorage.getItem("Year"));

  const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const head = ["Equity", ...Month];

  const { response: responseAutomation, loading: loadingAutomation, error: errorAutomation } = Api({
    modal: 'monthly_equity-entry',
    UserId: Userid,
    CompanyId: CompanyId,
    method: 'GET'
  });

  useEffect(() => {
    if (loadingAutomation) {
      console.log('Loading accrued expenses errorAutomation...');
    }
    if (errorAutomation) {
      console.error('Error fetching accrued expenses:', errorAutomation.message);
    }
    if (responseAutomation) {
      console.log('Automation fetched successfully:', responseAutomation);
      const formattedData = responseAutomation.keys.map(item => ({
        value: item,
        label: item
      }));
      setDropdownAutomationdata(formattedData);
    }
  }, [responseAutomation, loadingAutomation, errorAutomation]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "Year") {
        setYear(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [year]);

  const Ai = {
    monthlyHeadingText: 'Automation Entry',
    formSections: [
      {
        heading: '',
        selectOptions1: DropdownAutomationdata || [],
        inputPlaceholder: 'Amount',
        buttonText: 'Start Automation',
        type: 'number'
      },
    ],
  };

  const salesData = {
    monthlyHeadingText: 'Monthly Equity Entry',
    formSections: [
      {
        heading: '',
        selectOptions1: DropdownAutomationdata || [],
        selectOptions2: Month.map((item, index) => ({
          value: index + 1,
          label: item
        })),
        inputPlaceholder: 'Amount',
        buttonText: 'Update',
        type: 'number'
      },
    ],
  };

  const [getLoading1, setGetLoading1] = useState(false);
  const [getError, setGetError] = useState(null);
  const fetchData = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const url = `${baseUrl}/calculate-equity?year=${year}&comp_id=${CompanyId}`;
      const response = await fetch(url);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
      setEquityData(data);
      setGetError(null);
    } catch (error) {
      setGetError(error.message);
    } finally {
      setGetLoading1(false);
    }
  };

  const handleUpdate = async (dropdownValue, monthValue, amountValue) => {
    setGetLoading(true);
    if (dropdownValue === 'Paid-In-Capital') {
      dropdownValue = 'Paid_Capital';
    } else if (dropdownValue === 'Dividends Paid') {
      dropdownValue = 'Dividends_Paid';
    }
    try {
      const apiUrl = `https://laravel.cashflow365.se/api/update/equity?year=${year}&comp_id=${CompanyId}`;
      const requestBody = {
        dropdown: dropdownValue,
        month: monthValue,
        Amount: amountValue
      };
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      console.log('Update response:', data);
      setGetLoading(false);
      fetchData();
    } catch (error) {
      console.error('Error updating equity:', error);
    }
  };

  const handleInputChange = (event, sectionIndex) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    console.log(newValue);
  };

  const handleButtonClick = async (sectionIndex) => {
    const dropdownValue = selectedDropdownValue;
    const monthValue = selectedMonthValue;
    const amountValue = inputValue;
    console.log(`dropdownValue: ${inputValue}`);
    console.log(`monthValue: ${monthValue}`);
    handleUpdate(dropdownValue, monthValue, amountValue);
    setInputValue('');
  };

  const handleSelect1Change = (selectedOption, sectionIndex) => {
    if (sectionIndex === 0) {
      setSelectedDropdownValue(selectedOption);
    }
  };

  const handleSelect2Change = (selectedOption, sectionIndex) => {
    if (sectionIndex === 0) {
      setSelectedMonthValue(selectedOption);
    }
  };

  const rows = [
    ['Beginning Month Balance', ...Object.values(equityData?.beg_bal || {})],
    ['Paid-In Capital', ...Object.values(equityData?.paid_cap || {})],
    ['Dividends Paid', ...Object.values(equityData?.div_paid || {})],
    ['End of Month Balance', ...Object.values(equityData?.end_bal || {})],
  ];

  const handleAutomationInputChange = (event) => {
    const newValue = event.target.value;
    setAutomationInputValue(newValue);
    console.log(`Automation Input Value: ${newValue}`);
  };

  const handleAutoSelect1Change = (selectedOption, sectionIndex) => {
    console.log("selectedOption" + selectedOption);
    setAutomationDropdown(selectedOption);
  };

  const handleAutomationButtonClick = async () => {
    const dropdownValue = automationDropdown;
    const amountValue = automationInputValue;
    console.log(`dropdownValue: ${inputValue}`);
    handleautoMationUpdate(dropdownValue, amountValue);
    setAutomationInputValue('');
  };

  const handleautoMationUpdate = async (dropdownValue, amountValue) => {
    setGetLoading(true);
    try {
      const apiUrl = `https://laravel.cashflow365.se/api/automation?year=${year}&comp_id=${CompanyId}`;
      const requestBody = {
        dropdown: dropdownValue,
        module: "equity",
        subModule: "",
        value: amountValue
      };
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      console.log('Update response:', data);
      setGetLoading(false);
      fetchData();
    } catch (error) {
      console.error('Error updating equity:', error);
    }
  };

  if (getLoading) return <div className='loader-container'>
        <div class="loader"></div>
    </div>;

  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='formBoxMonthly'>
              <div className='MonthlyHeading p-2'>
                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
              </div>
              <FormSection
                sections={Ai.formSections}
                productAndServicesLabel="Product and Services"
                selectPlaceholder="Select Value"
                handleButtonClick={handleAutomationButtonClick}
                handleSelect1Change={handleAutoSelect1Change}
                handleInputChange={handleAutomationInputChange}
                inputValue={automationInputValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='formBoxMonthly'>
              <div className='MonthlyHeading p-2'>
                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
              </div>
              <FormSection
                sections={salesData.formSections}
                productAndServicesLabel="Select type Details"
                selectPlaceholder="Select Value"
                handleButtonClick={handleButtonClick}
                handleInputChange={handleInputChange}
                handleSelect1Change={handleSelect1Change}
                handleSelect2Change={handleSelect2Change}
                inputValue={inputValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-lg-12 '>
            {equityData && Array.isArray(rows) && rows.length > 0 && (
              <CustomTable head={head} rows={rows} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Equity;
