import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table';
import Api from '../api/Api';

const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Sum"];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
const head = ["Income Tax", ...month];

const Ai = {
    monthlyHeadingText: 'Automation Entry',
    formSections: [
        {
            heading: '',
            selectOptions1: [
                { value: 'Tax Rate %', label: 'Tax Rate %' },
            ],
            inputPlaceholder: 'Tax Rate %',
            buttonText: 'Start Automation',
            type: 'number'
        },
    ],
};

const IncomeTex = () => {
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    const [Year, setYear] = useState(localStorage.getItem('Year')); // State to store Year

    const [incomeTaxData, setIncomeTaxData] = useState(null);
    const [dropdownData, setDropdownData] = useState(null);
    const [inputValues, setInputValues] = useState('');
    const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
    const [selectedMonthValue, setSelectedMonthValue] = useState('');
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);
    const [IncomeInputValue, setIncomeInputValue] = useState('');
    const [IncomeDropdown, setIncomeDropdown] = useState('');
    const [getLoading1, setGetLoading1] = useState(false);
    const [rows, setRows] = useState([]);

    const fetchData = async () => {
        setGetLoading1(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/calculate_income_tax?year=${Year}&comp_id=${CompanyId}`;
            console.log(url)
            const response = await fetch(url);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }
            setIncomeTaxData(data);
            setGetError(null);
            updateRows(data); // Update rows based on new data
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading1(false);
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            setYear(localStorage.getItem('Year'));
            // Optionally, you can choose to reload the page here if needed
            // window.location.reload();
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, [Year]); // Re-fetch data when Year changes

    const { response: dropdownResponse, loading: dropdownLoading, error: dropdownError } = Api({
        modal: 'income_tax_dropdown',
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET'
    });

    useEffect(() => {
        if (dropdownLoading) {
            console.log('Loading dropdown data...');
        }
        if (dropdownError) {
            console.error('Error fetching dropdown data:', dropdownError.message);
        }
        if (dropdownResponse) {
            console.log('Dropdown data fetched successfully:', dropdownResponse.data);
            const formattedDropdownData = dropdownResponse.data.map(item => ({
                value: item,
                label: item
            }));
            setDropdownData(formattedDropdownData);
        }
    }, [dropdownResponse, dropdownLoading, dropdownError]);

    useEffect(() => {
        if (dropdownData) {
            Ai.formSections[0].selectOptions1 = dropdownData;
            salesData.formSections[0].selectOptions1 = dropdownData;
            salesData.formSections[0].selectOptions2 = month.map((item, index) => ({
                value: index + 1,
                label: item
            }));
        }
    }, [dropdownData]);

    const salesData = {
        monthlyHeadingText: 'Monthly Income Tax Entry',
        formSections: [
            {
                heading: '',
                selectOptions1: [
                    { value: 'Tax Rate %', label: 'Tax Rate %' },
                ],
                selectOptions2: month.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
        ],
    };

    const handleUpdate = async (dropdownValue, monthValue, amountValue) => {
        setGetLoading(true);
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/update/incomeTaxNew?year=${Year}&comp_id=${CompanyId}`;
            const requestBody = {
                month: monthValue,
                taxRate: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData(); // Refresh data after update
        } catch (error) {
            console.error('Error updating income tax data:', error);
        }
    };

    const handleInputChange = (event, sectionIndex) => {
        const newValue = event.target.value;
        setInputValues(newValue);
    };

    const handleButtonClick = async (sectionIndex) => {
        const dropdownValue = selectedDropdownValue;
        const monthValue = selectedMonthValue;
        const amountValue = inputValues;
        console.log(`dropdownValue: ${dropdownValue}`);
        console.log(`monthValue: ${monthValue}`);
        handleUpdate(dropdownValue, monthValue, amountValue);
        setInputValues(amountValue);
    };

    const handleSelect1Change = (selectedOption, sectionIndex) => {
        console.log('selectedOption' + selectedOption);
        if (sectionIndex === 0) {
            setSelectedDropdownValue(selectedOption);
        }
    };

    const handleSelect2Change = (selectedOption, sectionIndex) => {
        if (sectionIndex === 0) {
            setSelectedMonthValue(selectedOption);
        }
    };

    const calculateSum = (data,key) => {
        if (!data) return 0;
        console.log("datdatdatda ",key)
        if(key=="Tax Rate %") return '';
        return months.reduce((sum, month) => sum + parseInt(data[month] || '0', 10), 0);
    };

    const updateRows = (data) => {
        const newRows = data
            ? Object.keys(data).map((key) => [
                key,
                ...months.map((month) => (data[key][month])),calculateSum(data[key],key)

               
            ])
            : [];
        setRows(newRows);
    };

    const handleIncomeInputChange = (event) => {
        const newValue = event.target.value;
        setIncomeInputValue(newValue);
        console.log(`Automation Input Value: ${newValue}`);
    };

    const handleIncomeSelect1Change = (selectedOption, sectionIndex) => {
        console.log("selectedOption" + selectedOption);
        setIncomeDropdown(selectedOption);
    };

    const handleIncomeButtonClick = async () => {
        const dropdownValue = IncomeDropdown;
        const amountValue = IncomeInputValue;
        handleIncomeTaxUpdate(dropdownValue, amountValue);
        setIncomeInputValue('');
    };

    const handleIncomeTaxUpdate = async (dropdownValue, amountValue) => {
        setGetLoading(true);
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/automation?year=${Year}&comp_id=${CompanyId}`
            const requestBody = {
                dropdown: dropdownValue,
                module: "incomeTax",
                subModule: "",
                value: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData(); // Refresh data after update
        } catch (error) {
            console.error('Error updating equity:', error);
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            setYear(localStorage.getItem('Year'));
            // Optionally, you can choose to reload the page here if needed
            // window.location.reload();
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    if (getLoading) return <div className='loader-container'>
        <div class="loader"></div>
    </div>;

    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                productAndServicesLabel="Product and Services"
                                selectPlaceholder="Select Value"
                                handleButtonClick={() => handleIncomeButtonClick()}
                                handleSelect1Change={handleIncomeSelect1Change}
                                handleInputChange={(event) => handleIncomeInputChange(event)}
                                inputValue={IncomeInputValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={salesData.formSections}
                                productAndServicesLabel="Select type Details"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleButtonClick}
                                handleInputChange={handleInputChange}
                                handleSelect1Change={handleSelect1Change}
                                handleSelect2Change={handleSelect2Change}
                                inputValues={inputValues}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        <CustomTable head={head} rows={rows} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default IncomeTex;
