import React, { useState } from 'react';
import Api from '../api/Api';
import AiRepost from '../JesonAi/AiRepost'
const Year = localStorage.getItem("Year")

function AiReports() {
    const [openSection, setOpenSection] = useState(null);
    const [overviewdata, setOverview] = useState('');
    const [questiondata, setQuestion] = useState('');
    const [balancesheetcontent, setbalancesheetcontent] = useState('')
    const [cashflowcontent, setcashflowcontent] = useState('')
    const [incomestatementcontent, setincomestatementcontent] = useState('')
    const [summary, setsummary] = useState('')
    const [getLoading, setGetLoading] = useState(false);

    const toggleSection = (index) => {
        setOpenSection(openSection === index ? null : index);
    };

    const handleOverviewChange = (event) => {
        setOverview(event.target.value);
    };

    const handleQuestionChange = (event) => {
        setQuestion(event.target.value);
    };
    const balance_sheet_data_new = localStorage.getItem('balanceSheet');
    const income_statement_data_new = localStorage.getItem('balanceRes');
    const cashflow_data_new = localStorage.getItem('Cashflow');
    console.log("balance_sheet_data_new" + balance_sheet_data_new)
    var summary_data_new;
    const contentLoader =
        '<div class="loadernew"><div class="loader">' +
        '<div><ul><li><svg fill="currentColor" viewBox="0 0 90 120"><path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>' +
        '</svg></li><li><svg fill="currentColor" viewBox="0 0 90 120">' +
        '<path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>' +
        '</svg></li><li><svg fill="currentColor" viewBox="0 0 90 120"><path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>' +
        '</svg></li><li><svg fill="currentColor" viewBox="0 0 90 120"><path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>' +
        '</svg></li><li><svg fill="currentColor" viewBox="0 0 90 120"><path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>' +
        '</svg></li><li><svg fill="currentColor" viewBox="0 0 90 120"><path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>' +
        "</svg></li></ul></div><span>Loading</span></div></div>";
    const handleCreateReport = () => {
        setGetLoading(true)
        var globalvalue = 0;
        var balancesheetid = "";
        var incomestatementid = "";
        var cashflowid = "";
        var summaryid = "";
        var balance_sheet_data = balance_sheet_data_new;
        var balance_sheet_data_count = 0;
        var cash_flow_data = cashflow_data_new;
        var income_statement_data = income_statement_data_new;
        var summary_data = "";
        // document.getElementById("balance-sheet-content").innerHTML = contentLoader;
        // document.getElementById("income-statement-content").innerHTML = contentLoader;
        // document.getElementById("cashflow-content").innerHTML = contentLoader;
        // document.getElementById("summary-content").innerHTML = contentLoader;
        // const formData = new FormData();
        const question = questiondata;
        const overview = overviewdata;
        console.log(question)
        //web socket start
        fetch("https://api.finansinsikt.se/v2/ticket/", {
            method: "POST",
            headers: {
                Authorization: "Bearer cb661e9f-43c3-4d68-b19e-7d15c215f51b",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const ticket = data.ticket; // Retrieval of the ticket

                //new response start
                const ws = new WebSocket("wss://api.finansinsikt.se/v2/finansgpt/ws/");

                ws.addEventListener("open", function () {
                    ws.send(data.ticket); // Send the ticket for validation
                });
                console.log(questiondata)
                console.log(overviewdata)
                let previousResponse = null;
                ws.addEventListener("message", function (event) {
                    const response = JSON.parse(event.data);

                    if (response.action === "ticket_valid") {
                        ws.send(
                            JSON.stringify({
                                //   case_id: caseId,
                                question: question,
                                overview: overview,
                                balance_sheet: balance_sheet_data_new,
                                income_statement: income_statement_data_new,
                                cash_flow_statement: cashflow_data_new,
                            })
                        );
                    }

                    // console.log("token value is :- " + JSON.stringify(response));

                    if (previousResponse != null) {
                        //check data for balance sheet
                        if (
                            response.action === "start" &&
                            response.payload.id === "balance_sheet_analysis"
                        ) {
                            balance_sheet_data = "";
                        }
                        if (
                            response.action === "end" &&
                            response.payload.id === "balance_sheet_analysis"
                        ) {
                            if (
                                previousResponse.action === "token" &&
                                previousResponse.payload.id === "balance_sheet_analysis"
                            ) {
                                balance_sheet_data = previousResponse.payload.token;
                                balance_sheet_data = balance_sheet_data.replace(/\\"/g, '"');
                                balance_sheet_data = JSON.stringify(balance_sheet_data);
                                balance_sheet_data = JSON.parse(balance_sheet_data);
                            }
                        }
                        //check data for cash flow
                        if (
                            response.action === "start" &&
                            response.payload.id === "cash_flow_statement_analysis"
                        ) {
                            cash_flow_data = "";
                        }
                        if (
                            response.action === "end" &&
                            response.payload.id === "cash_flow_statement_analysis"
                        ) {
                            if (
                                previousResponse.action === "token" &&
                                previousResponse.payload.id === "cash_flow_statement_analysis"
                            ) {
                                cash_flow_data = previousResponse.payload.token;
                            }
                        }
                        //check data for income statement
                        if (
                            response.action === "start" &&
                            response.payload.id === "income_statement_analysis"
                        ) {
                            income_statement_data = "";
                        }
                        if (
                            response.action === "end" &&
                            response.payload.id === "income_statement_analysis"
                        ) {
                            if (
                                previousResponse.action === "token" &&
                                previousResponse.payload.id === "income_statement_analysis"
                            ) {
                                income_statement_data = previousResponse.payload.token;
                            }
                        }
                        //check summary data
                        if (
                            (response.action === "start" || response.action === "token") &&
                            response.payload.id === "summary"
                        ) {
                            if (response.action === "start") {

                            }
                            summary_data_new = summary_data_new + response.payload.token;
                        }
                        if (
                            response.action === "end" &&
                            response.payload.id === "summary"
                        ) {
                            // var text = (summary_data_new);
                            // text = text.replace(/NaN###/g, '');
                            // text = text.replace(/Sammanfattning/g, '');
                            // document.getElementById("summary-content").innerHTML = "";
                            // document.getElementById("summary-content").innerHTML = text;
                            console.log("Summary data is : ", summary_data_new)
                            // const parsedData = JSON.parse(summary_data_new);
                            // setsummary(parsedData);
                            setsummary(summary_data_new)
                        }

                    }

                    previousResponse = response;
                    if (balance_sheet_data.length > 0) {
                        // document.getElementById("balance-sheet-content").innerHTML = "";

                        // var dataNew = balance_sheet_data.replace(/\\"/g, '"');
                        // var ConvertedData = JSON.stringify(dataNew);
                        // var newData = JSON.parse(ConvertedData);

                        // var data = displayJsonData(
                        //     balance_sheet_data,
                        //     "balance-sheet-content"
                        // );
                        console.log("balance sheet content : ", balance_sheet_data)
                        // console.log("balance sheet new content is : ", newData)
                        setbalancesheetcontent(balance_sheet_data)
                        //document.getElementById("balance-sheet-content").innerHTML = data;
                    }
                    if (cash_flow_data.length > 0) {
                        // document.getElementById("cashflow-content").innerHTML = "";

                        var dataNew = cash_flow_data.replace(/\\"/g, '"');
                        var ConvertedData = JSON.stringify(dataNew);
                        var newData = JSON.parse(ConvertedData);

                        // var data = displayJsonData(newData, "cashflow-content");
                        console.log("cashflow content : ", newData)
                        setcashflowcontent(newData)

                        //document.getElementById("cashflow-content").innerHTML = data;
                    }
                    if (income_statement_data.length > 0) {
                        // document.getElementById("income-statement-content").innerHTML = "";

                        var dataNew = income_statement_data.replace(/\\"/g, '"');
                        var ConvertedData = JSON.stringify(dataNew);
                        var newData = JSON.parse(ConvertedData);

                        // var data = displayJsonData(newData, "income-statement-content");

                        console.log("income statement content : ", newData)
                        setincomestatementcontent(newData)
                        //document.getElementById("income-statement-content").innerHTML = data;
                    }

                });

                ws.addEventListener("error", function (event) {
                    const response = JSON.parse(event.data);
                    console.log("error ;- " + JSON.stringify(response));
                    if (response.action.code === "bad_ticket") {
                        console.log("Authorization error: Invalid ticket provided.");
                    }
                }); //new response end
                setGetLoading(false)
            }) //websocket end
            .catch((e) => {
                console.log("web error :-" + JSON.stringify(e));
            });
    }; //on load end



    // Function to convert escaped characters to actual values
    function convertEscapedCharacters(str) {
        return str.replace(/\\u([\d\w]{4})/gi, function (match, grp) {
            return String.fromCharCode(parseInt(grp, 16));
        });
    }

    // Function to display JSON data in the HTML div
    function displayJsonData(data, contentDivId) {
        var div = document.createElement("div");
        var newData = JSON.parse(data);
        console.log("new Data is  :- " + JSON.stringify(newData));
        newData.forEach(function (item) {
            for (var key in item) {
                if (item.hasOwnProperty(key)) {
                    // Create a paragraph element and append it to the div
                    var paragraph = document.createElement("p");
                    paragraph.textContent = key + ": " + item[key];
                    div.appendChild(paragraph);
                }
            }
        });

        removeSections(div, contentDivId);

    }

    function removeSections(element, contentDivId) {
        // Extract the text content from the HTML element
        // var text = element.textContent || element.innerText || '';
        var text = '';


        text = text.replace(/observation:/g, '\n\nObservation : ');
        text = text.replace(/evidence:/g, '\n\nReferens : ');
        text = text.replace(/calculations:/g, '\n\nUträkningar : ');
        text = text.replace(/recommendation:/g, '\n\nRekommendationer : ');
        text = text.replace(/risk:/g, '\n\nRisker :');

        // console.log("text is :- " + text);
        //get the count of words
        const occurrences = countOccurrences(text, "Uträkningar : ");

        //custom logic for checking nullable start
        for (var i = 0; i < occurrences; i++) {
            var startIndex1 = text.indexOf("Observation : ");
            var startIndex2 = text.indexOf("Referens : ");
            var startIndex3 = text.indexOf("Uträkningar : ");
            var startIndex4 = text.indexOf("Rekommendationer : ");
            var startIndex5 = text.indexOf("Risker : ");

            const tempText = text.substring((startIndex1 + 13), startIndex2);
            // console.log("temp Text length is 1 :- " + tempText.length);
            if (tempText.length == 0) {
                text = text.replace(/Observation :/g, '');
            }

            const tempText1 = text.substring((startIndex2 + 14), startIndex3);
            // console.log("temp Text length is 2 :- " + tempText1.length);
            if (tempText1.length == 0) {
                text = text.replace(/Referens :/g, '');
            }
            const tempText2 = text.substring((startIndex3 + 17), startIndex4);
            console.log("temp Text length is 3 :- " + tempText2.length);
            if (tempText2.length == 0) {
                text = text.replace(/Uträkningar :/g, '');
            }
            const tempText3 = text.substring((startIndex4 + 22), startIndex5);
            //console.log("temp Text length is :- 4 " + tempText3.length);
            if (tempText3.length == 0) {
                text = text.replace(/Rekommendationer :/g, '');
            }
            const tempText4 = text.substring((startIndex5 + 12));
            // console.log("temp Text length is 5 :- " + tempText4.length);
            if (tempText4.length == 0) {
                text = text.replace(/Risker :/g, '');
            }
        }


        console.log("Text is : ", text);

        //custom logic for checking nullable end

        // console.log("second text is :- " + text);
        var targetDiv = document.getElementById(contentDivId);

        // targetDiv.innerHTML = "";
        // targetDiv.innerText = text.trim();
    }

    //count word
    function countOccurrences(sentence, word) {
        // Convert both the sentence and word to lowercase to make the search case-insensitive
        const lowerCaseSentence = sentence.toLowerCase();
        const lowerCaseWord = word.toLowerCase();

        // Use a regular expression to match the word globally in the sentence
        const regex = new RegExp('\\b' + lowerCaseWord + '\\b', 'g');
        const matches = lowerCaseSentence.match(regex);

        // If matches is null, the word was not found
        // Otherwise, return the number of occurrences
        return matches ? matches.length : 0;
    }

    const AIdata = [
        { key: 'summary', value: 'Summary', data: summary },
        { key: 'incomeStatement', value: 'Income Statement', data: incomestatementcontent },
        { key: 'balanceSheet', value: 'Balance Sheet', data: balancesheetcontent },
        { key: 'cashFlow', value: 'Cash Flow', data: cashflowcontent },
    ]
    console.log("AI Data is : ", AIdata)

    if (getLoading) return <div className='loader-container'>
        <div className='loader'></div>
    </div>;

    return (
        <>
            <AiRepost />
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            {/* <div className='MonthlyHeading p-2'>
                            <span className='MonthlyHeadingText'>Type your query for search</span>
                        </div> */}
                            <div className='FormContainer'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='form-group'>
                                                <label htmlFor="companyOverview" className='formHeadingSales'>Company Overview</label>
                                                <textarea
                                                    id="companyOverview"
                                                    className='form-control aiinputbox'
                                                    placeholder="Company overview to create AI Report"
                                                    value={overviewdata}
                                                    onChange={handleOverviewChange}
                                                    rows="3"
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor="question" className='formHeadingSales'>Question</label>
                                                <textarea
                                                    id="question"
                                                    type='text'
                                                    className='form-control aiinputbox'
                                                    placeholder="Question to get AI Report"
                                                    value={questiondata}
                                                    rows="3"
                                                    onChange={handleQuestionChange}
                                                />
                                            </div>
                                            <button
                                                className='btn btn-primary mt-3' style={{ margin: '20px', backgroundColor: '#114CB2' }}
                                                onClick={handleCreateReport}
                                            >
                                                Create AI Report
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ paddingBottom: '100px' }}>
                {AIdata.map((section, index) => (
                    <div className='container mt-4' key={index}>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='formBoxMonthly'>
                                    <div className='MonthlyHeading aiButtonCard p-2' onClick={() => toggleSection(index)}>
                                        <span className='MonthlyHeadingText'>{section.value}</span>
                                    </div>
                                    {openSection === index && (
                                        <div className='SummaryDetails'>
                                            {section[index] ? (
                                                <div className='loader-container'>
                                                <div className='loader'></div>
                                            </div>
                                            ) : (
                                                (() => {
                                                    let content = section.data;
                                                    content = content.replace(/NaN###/g, '');

                                                    let parsedData;
                                                    try {
                                                        parsedData = JSON.parse(content);
                                                    } catch (error) {
                                                        console.error("Error parsing JSON:", error);
                                                        parsedData = null;
                                                    }

                                                    if (Array.isArray(parsedData)) {
                                                        return parsedData.map((item, idx) => (
                                                            <div key={idx} className='summaryItem'>
                                                                {Object.entries(item).map(([key, value]) => (
                                                                    value && !/NaN|###/i.test(key) && !/NaN|###/i.test(value) && (
                                                                        <div key={key} className='summaryEntry'>
                                                                            <h3>{key}:</h3>
                                                                            <p>{value}</p>
                                                                        </div>
                                                                    )
                                                                ))}
                                                            </div>
                                                        ));
                                                    }

                                                    if (!parsedData) {
                                                        return content.split('\n').map((line, idx) => (
                                                            <div key={idx} className='summaryEntry'>
                                                                <p>{line}</p>
                                                            </div>
                                                        ));
                                                    }

                                                    return <p>Invalid data format</p>;
                                                })()
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </div>


        </>
    );
}

export default AiReports;
