import React, { useState } from "react";
import imageCompression from 'browser-image-compression';

// Environment variable for the year
const Year = process.env.REACT_APP_API_BASE_Year;

const UpdateProfile = () => {
    const [image, setImage] = useState(null);
    const [base64Image, setBase64Image] = useState('');
    const CompanyId = localStorage.getItem('CompanyId');
    const [getLoading, setGetLoading] = useState(false);

    // Function to handle image selection and compression
    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        setImage(file);

        if (!file) return;

        try {
            // Options for image compression
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1024,
                useWebWorker: true,
            };

            // Compress the image file
            const compressedFile = await imageCompression(file, options);

            // Convert compressed image to Base64
            const reader = new FileReader();
            reader.onloadend = () => {
                // Extract the Base64 part only (excluding the data URL header)
                setBase64Image(reader.result.split(',')[1]);
            };
            reader.readAsDataURL(compressedFile);
        } catch (error) {
            console.error('Error compressing image:', error);
        }
    };

    // Function to handle form submission
    const handleButtonClick = async () => {
        if (!base64Image) {
            alert('Please select an image.');
            return;
        }

        setGetLoading(true);

        const requestBody = {
            image: base64Image,
            comp_id: CompanyId,
        };

        try {
            const response = await fetch(`https://laravel.cashflow365.se/api/update/updateProfile?year=${Year}&comp_id=${CompanyId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("data ",data.Profile);
                localStorage.setItem('Profile', data.Profile);
                window.location.reload();
                setImage(null);
                setBase64Image('');
                setGetLoading(false);
            } else {
                const errorData = await response.json();
                alert(`Error: ${errorData.message}`);
                setGetLoading(false);
            }
        } catch (error) {
            alert(`Error: ${error.message}`);
            setGetLoading(false);
        }
    };

    // Optional: Function to convert Base64 to binary
    const base64ToBinary = (base64) => {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    };

    // Display a loading spinner while waiting for the response
    if (getLoading) {
        return (
            <div className='loader-container'>
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className='container mt-5'>
            <div className='FormContainer'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formHeadingSales'>
                            <label>Upload Image</label>
                        </div>
                        <div className='inputboxdiv'>
                            <input
                                type='file'
                                className='inputbox'
                                onChange={handleImageChange}
                            />
                            <input
                                type='button'
                                className='inputboxButton'
                                value='Update'
                                onClick={handleButtonClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateProfile;
