import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table';

const Ai = {
    monthlyHeadingText: 'Product Detail',
    formSections: [
        {
            heading: '',
            inputPlaceholder: 'Product Name',
            buttonText: 'Update',
            type: 'text'
        },
    ],
};

const head = [ 'Products', 'Created At', 'Updated At', ''];

function AddProduct() {
    const [year, setYear] = useState(localStorage.getItem("Year"));
    const [getProducts, setGetProducts] = useState([]);
    const [newProduct, setNewProduct] = useState('');
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [postError, setPostError] = useState(null);
    const [postLoading, setPostLoading] = useState(false);
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);

    const CompanyId = localStorage.getItem('CompanyId');

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "Year") {
                setYear(event.newValue);
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const fetchProducts = async () => {
        setGetLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/add_product?year=${year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }

            const formattedData = data.products.map(item => ({
                ...item,
                created_at: formatDate(item.created_at),
                updated_at: formatDate(item.updated_at)
            }));

            setGetProducts(formattedData);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [year]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }); // This will format the date to the local date string
    };

    const changeProduct = (product, id) => {
        setNewProduct(product);
        setSelectedProductId(id);
    };

    const rows = getProducts
        .filter(product => product && product.product_name !== undefined)
        .map((product, index) => ({
            id: index + 1,
            product_name: product.product_name,
            created_at: formatDate(product.created_at),
            updated_at: formatDate(product.updated_at),
            button: <input type='button' className='inputboxChangeButton' onClick={() => changeProduct(product.product_name, product.id)} value='Change' />
        }));

    const arrayOfArrays = rows.map(row => [
        // row.id,
        row.product_name,
        row.created_at,
        row.updated_at,
        row.button
    ]);

    const handleButtonClick = async () => {
        if (!newProduct.trim()) {
            return;
        }
        setPostLoading(true);

        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            let url;
            let options;

            if (selectedProductId) {
                url = `${baseUrl}/update_productdata?year=${year}&comp_id=${CompanyId}&id=${selectedProductId}`;
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ product_name: newProduct, CompanyId })
                };
            } else {
                url = `${baseUrl}/create_productdata?year=${year}&comp_id=${CompanyId}`;
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ product_name: newProduct, CompanyId })
                };
            }

            const response = await fetch(url, options);
            const result = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }

            setPostError(null);
            setNewProduct('');
            setSelectedProductId(null);
            fetchProducts(); // Fetch the latest products after adding/updating
        } catch (error) {
            setPostError(error.message);
        } finally {
            setPostLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setNewProduct(e.target.value);
    };

    if (getLoading) return <div className='loader-container'>
        <div class="loader"></div>
    </div>;

    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection 
                                sections={Ai.formSections} 
                                productAndServicesLabel="Product and Services" 
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleButtonClick}
                                handleInputChange={handleInputChange}
                                inputValue={newProduct} // Pass the current input value
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <CustomTable head={head} rows={arrayOfArrays} />
                    </div>
                </div>
            </div>
            {/* {postLoading && <p>Updating...</p>}
            {postError && <p>Error: {postError}</p>} */}
        </>
    );
}

export default AddProduct;
