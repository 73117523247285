import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table';

const Ai = {
    monthlyHeadingText: 'Salary & Wages Detail',
    formSections: [
        {
            heading: '',
            inputPlaceholder: 'Salary & Wages Name',
            buttonText: 'Update',
            type: 'text'
        },
    ],
};

const head = [ 'Salary & Wages', 'Created At', 'Updated At', ''];

function AddSalaryWages() {
    const [year, setYear] = useState(localStorage.getItem("Year"));
    const [GetSalaryWages, setGetSalaryWages] = useState([]);
    const [NewSalaryWages, setNewSalaryWages] = useState('');
    const [selectedSalaryWagesId, setSelectedSalaryWagesId] = useState(null);
    const [postError, setPostError] = useState(null);
    const [postLoading, setPostLoading] = useState(false);
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);

    const CompanyId = localStorage.getItem('CompanyId');

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "Year") {
                setYear(event.newValue);
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const fetchSalaryWages = async () => {
        setGetLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/add_salary_wages_details?year=${year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }

            const formattedData = data.salary_wages_details.map(item => ({
                ...item,
                created_at: formatDate(item.created_at),
                updated_at: formatDate(item.updated_at)
            }));

            setGetSalaryWages(formattedData);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading(false);
        }
    };

    useEffect(() => {
        fetchSalaryWages();
    }, [year]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    };

    const handleButtonClick = async (id, name) => {
        setNewSalaryWages(name);
        setSelectedSalaryWagesId(id);
    };

    const handleSalaryWagesButtonClick = async () => {
        if (!NewSalaryWages.trim()) {
            return;
        }
        setPostLoading(true);

        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            let url;
            let options;

            if (selectedSalaryWagesId) {
                url = `${baseUrl}/update_salary_wages_details?year=${year}&comp_id=${CompanyId}&id=${selectedSalaryWagesId}`;
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ salaryname: NewSalaryWages, CompanyId })
                };
            } else {
                url = `${baseUrl}/create_salary_wages_details?year=${year}&comp_id=${CompanyId}`;
                options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ salaryname: NewSalaryWages, CompanyId })
                };
            }

            const response = await fetch(url, options);
            const result = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }

            setPostError(null);
            setNewSalaryWages('');
            setSelectedSalaryWagesId(null);
            fetchSalaryWages(); // Fetch the latest SalaryWages after adding/updating
        } catch (error) {
            setPostError(error.message);
        } finally {
            setPostLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setNewSalaryWages(e.target.value);
    };

    if (getLoading) return <div className='loader-container'>
        <div class="loader"></div>
    </div>;
    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                SalaryWagesAndServicesLabel="SalaryWages and Services"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleSalaryWagesButtonClick}
                                handleInputChange={handleInputChange}
                                inputValue={NewSalaryWages} // Pass the current input value
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <CustomTable head={head} rows={GetSalaryWages.map((data, index) => [
                                data.salary_name,
                                formatDate(data.created_at),
                                formatDate(data.updated_at),
                                <input
                                    type='button'
                                    className='inputboxChangeButton'
                                    onClick={() => handleButtonClick(data.id, data.salary_name)}
                                    value='Change'
                                />,
                            ])} />
                    </div>
                </div>
            </div>
            {/* {postLoading && <p>Updating...</p>}
            {postError && <p>Error: {postError}</p>} */}
        </>
    );
}

export default AddSalaryWages;
