import React from 'react';
import Chart from '../layout/Charts';

const App = () => {
    const data = [100, 150, 120,78,95,150,60,166]; // Example data

    return (
        <div className='mt-5'>
            <Chart data={data} />
        </div>
    );
};

export default App;
