import React, { useState, useEffect } from 'react';
import BarChart from '../components/Charts/BarCharts';
import LineChart from '../components/Charts/LineChart';
import Api from '../api/Api'; // Make sure you import your API hook

const CustomBarChart = () => {
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    console.log('Userid: ' + Userid);
    console.log('CompanyId: ' + CompanyId);

    const [incomeTaxData, setIncomeTaxData] = useState([]);
    const [CashAndEquivalents, setCashAndEquivalents] = useState([])
    const [CashRatio, setCashRatio] = useState([])
    const [Revenue, setRevenue] = useState([])
    const [values, setValues] = useState([]);
    const { response, loading, error } = Api({
        modal: 'calculate_report',
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET'
    });
    useEffect(() => {
        if (loading) {
            console.log('Loading...');
        }
        if (error) {
            console.error('Error fetching data:', error.message);
        }
        if (response) {
            console.log('Data fetched successfully:', response);
            const datareport = response;
            const data = response.variables;
            const data2 = response.cash_equivalent_ope_bal
            console.log("Report Data " + JSON.stringify(data));

            //const cashEquivalent = data.cash_equivalent_ope_bal; 
            //console.log('cashEquivalent' + data2)

            if (data) {
                const transformedData = Object.keys(data.net_income).map((key) => {
                    // console.log('data.net_income[key]: ' + data.net_income[key]);
                    const monthIndex = parseInt(key) - 1;
                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    return { name: monthNames[monthIndex], value: data.net_income[key] };
                });
                const transformedData2 = Object.keys(data.end_of_mont_bal).map((key) => {
                    //console.log('data.end_of_mont_bal[key]: ' + data.end_of_mont_bal[key]);
                    const monthIndex = parseInt(key) - 1;
                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    return { name: monthNames[monthIndex], value: data.end_of_mont_bal[key] };
                });
                const transformedData3 = Object.keys(data.total_sale).map((key) => {
                    const monthIndex = parseInt(key) - 1;
                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    return { name: monthNames[monthIndex], value: data.total_sale[key] };
                });
                const transformedData4 = Object.keys(data.cash_ratio_calculation).map((key) => {
                    const monthIndex = parseInt(key);
                    const monthNames = ["Dec","Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov","Dec"];
                    return { name: monthNames[monthIndex], value: data.cash_ratio_calculation[key] };
                });

                const cash_equivalent = {
                    name: 'Dec',
                    value: data2
                }

                // console.log(data.cash_ratio_calculation)
                // const transformedDataratio = Object.keys(data.cash_ratio_calculation.map((key) => {
                //     const monthIndex = parseInt(key) - 1;
                //     const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec","dec"];
                //     return { name: monthNames[monthIndex], value: data.cash_ratio_calculation[key] };
                // }));

                // console.log("valueskbdf ", transformedDataratio)

                const dataEquivalents = [cash_equivalent, ...transformedData2]
                const datarevenue = [...transformedData3]
                 const ratio = [ ...transformedData4]
                console.log('Transformed Data: ', dataEquivalents);
                setIncomeTaxData(transformedData);
                setCashAndEquivalents(dataEquivalents);
                setCashRatio(ratio)
                setRevenue(datarevenue)
            } else {
                console.error('Net income data not found');
            }
        }
    }, [response, loading, error]);

    

    return (
        <>
            <div className='container chart-container'>
                <div className='row'>
                    <div className='col-lg-12 d-flex chart-col'>
                        <div className='ChartBox col-lg-6 mt-2'>
                            <div className=''>
                                <BarChart data={CashAndEquivalents} title='Cash and Cash Equivalents' />
                            </div>
                        </div>
                        <div className='ChartBox col-lg-6 mt-2'>
                            <div className=''>
                                <LineChart data={CashRatio} title='Cash Ratio' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container chart-container mt-2' style={{ marginBottom: 100 }}>
                <div className='row'>
                    <div className='col-lg-12 d-flex chart-col'>
                        <div className='ChartBox col-lg-6 mt-2'>
                            <div className=''>
                                <LineChart data={incomeTaxData} title='Net Income' />
                            </div>
                        </div>
                        <div className='ChartBox col-lg-6 mt-2'>
                            <div className=''>
                                <BarChart data={Revenue} title='Revenue' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomBarChart;
