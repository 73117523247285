import React, { useState, useEffect } from 'react';
import CustomTable from './Table/Table'; // Adjust the path as necessary
import '../assets/css/Form.css';
import FormSection from './Form/FormSection';

const head = [ 'Balance Name', 'Amount', 'Created At', 'Updated At'];

function AddOpeningBalance() {
    const [year, setYear] = useState(localStorage.getItem("Year"));
    const [openingBalances, setOpeningBalances] = useState({});
    const [newBalance, setNewBalance] = useState('');
    const [selectedBalance, setSelectedBalance] = useState(null);
    const [postError, setPostError] = useState(null);
    const [postLoading, setPostLoading] = useState(false);
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);
    const [totalAssets, setTotalAssets] = useState(0);
    const [totalLiabilitiesEquity, setTotalLiabilitiesEquity] = useState(0);
    const [difference, setDifference] = useState(0);

    const CompanyId = localStorage.getItem('CompanyId');

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "Year") {
                setYear(event.newValue);
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const fetchOpeningBalances = async () => {
        setGetLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL || "https://cashflowapi.concentics.in/api";
            const url = `${baseUrl}/add_opening_bal?year=${year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }

            const createdAt = data['Created At'];
            const updatedAt = data['Updated At'];

            delete data['Created At'];
            delete data['Updated At'];

            setOpeningBalances({ data, createdAt, updatedAt });

            const totalAssetsValue = Number(data['Cash and Cash Equivalent']) + 
                                     Number(data['Account Receivable']) + 
                                     Number(data['Inventories']) + 
                                     Number(data['Other Current Assets']) + 
                                     Number(data['Property Plant & Equipments']) + 
                                     Number(data['Long Term Investment']);
            const totalLiabilitiesEquityValue = Number(data['Account Payable']) + 
                                                Number(data['Accrued Expenses']) + 
                                                Number(data['Short Term Debt']) + 
                                                Number(data['Long Term Debt']) + 
                                                Number(data['Owners Investment']) + 
                                                Number(data['Retained Earnings']) + 
                                                Number(data['Net Income']);
            
            setTotalAssets(totalAssetsValue);
            setTotalLiabilitiesEquity(totalLiabilitiesEquityValue);
            setDifference(totalAssetsValue - totalLiabilitiesEquityValue);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading(false);
        }
    };

    useEffect(() => {
        fetchOpeningBalances();
    }, [year]);

    const handleInputChange = (e) => {
        setNewBalance(e.target.value);
    };

    const handleSelect1Change = (selectedOption) => {
        setSelectedBalance(selectedOption);
        const amount = openingBalances.data[selectedOption.value];
        setNewBalance(amount || '');
    };

    const handleButtonClick = async () => {
        if (!selectedBalance || !newBalance) {
            alert('Please select a balance and enter an amount');
            return;
        }
        console.log("selectedBalance "+selectedBalance)
        console.log("newBalance "+newBalance)
        setPostLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/update/add_open_bal?year=${year}&comp_id=${CompanyId}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    openingEntity: selectedBalance,
                    openingEntityValue: newBalance,
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to update: ${response.status} ${response.statusText}`);
            }

            setPostError(null);
            fetchOpeningBalances(); // Refresh the opening balances after successful update
            setNewBalance('');
            setSelectedBalance(null);
        } catch (error) {
            setPostError(error.message);
        } finally {
            setPostLoading(false);
        }
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    };
    const balanceArray = Object.entries(openingBalances.data || {}).map(([key, value], index) => ({
        srNo: index + 1,
        balanceName: key,
        amount: value,
        createdAt: (openingBalances.createdAt)?formatDate(openingBalances.createdAt):'',
        updatedAt: (openingBalances.updatedAt)?formatDate(openingBalances.updatedAt):''
        // button: <input type='button' className='inputboxChangeButton' onClick={() => handleSelect1Change({ value: key, label: key })} value='Change' />
    }));

    const dropdownData = Object.entries(openingBalances.data || {}).map(([key, value]) => ({
        balanceName: key,
    }));

    const balanceDropdown = dropdownData.map((data) => ({
        value: data.balanceName,
        label: data.balanceName,
    }));

    const arrayOfArrays = balanceArray.map(row => [
        // row.srNo,
        row.balanceName,
        row.amount,
        row.createdAt,
        row.updatedAt
    ]);

    const Ai = {
        monthlyHeadingText: 'Automation Entry',
        formSections: [
            {
                heading: '',
                selectOptions1: balanceDropdown || [],
                inputPlaceholder: 'Enter Opening Balance',
                buttonText: 'Update',
                type: 'number'
            },
        ],
    };

    if (getLoading) return <div className='loader-container'>
        <div className='loader'></div>
    </div>;

    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>Opening Balance Detail</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                productAndServicesLabel="Product and Services"
                                selectPlaceholder="Select"
                                handleButtonClick={handleButtonClick}
                                handleInputChange={handleInputChange}
                                handleSelect1Change={handleSelect1Change}
                                inputValue={newBalance}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className=' container mt-4 '>
                <div className='formBoxMonthly  FormContainer'>
                    <div className='row '>
                        <div className='MonthlyHeading p-2 '>
                            <span className='MonthlyHeadingText'>Difference</span>
                        </div>
                        <div className='col-lg-12 d-flex'>
                            <div>
                                <div className='formHeadingSales'><label>Total Assets</label></div>
                                <div className='inputboxdiv'>
                                    <input
                                        type='text'
                                        className='inputbox'
                                        placeholder=''
                                        disabled
                                        value={totalAssets}
                                    />
                                    <input
                                        type='text'
                                        className='inputboxNeg'
                                        placeholder='-'
                                        disabled
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='formHeadingSales'><label>Total Liabilities & Equity</label></div>
                                <div className='inputboxdiv'>
                                    <input
                                        type='text'
                                        className='inputbox'
                                        placeholder=''
                                        disabled
                                        value={totalLiabilitiesEquity}
                                    />
                                     <input
                                        type='text'
                                        className='inputboxPlus'
                                        placeholder='='
                                        disabled
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='formHeadingSales'><label>Difference</label></div>
                                <div className='inputboxdiv'>
                                    <input
                                        type='text'
                                        className='inputbox'
                                        placeholder=''
                                        disabled
                                        value={difference}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <CustomTable head={head} rows={arrayOfArrays} />
                    </div>
                </div>
            </div>
            {postLoading && <p>Updating...</p>}
            {postError && <p>Error: {postError}</p>}
        </>
    );
}

export default AddOpeningBalance;
