import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NavLink, useLocation } from 'react-router-dom';
import navConfig from "../../layout/Navigation";
import { setYearData } from '../demo';

const messageIcon = require('../../assets/img/message.png');
const logoutIcon = require('../../assets/img/logout.png');
const logo = require('../../assets/img/logoicon.png');
const defaultProfile = require('../../assets/img/Profile.png'); // Use default profile image

const Navbar = ({ onToggleSidebar, onLogout }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectDropdownYear, setSelectDropdownYear] = useState(localStorage.getItem('Year') || new Date().getFullYear());
  const location = useLocation();
  const profilebase64 = localStorage.getItem('Profile');
  console.log("profilebase64 "+profilebase64)
  const [profileImage, setProfileImage] = useState(defaultProfile);

  // Helper function to convert base64 to image format
  const getProfileImage = (base64) => {
    return `data:image/png;base64,${base64}`;
  };

  useEffect(() => {
    if (profilebase64!=null||profilebase64) {
      console.log("Profile Base64: ", profilebase64);
      setProfileImage(getProfileImage(profilebase64));
    }
    else
    {
      setProfileImage(defaultProfile);  
    }
  }, [profilebase64]);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    onToggleSidebar(); // Call the function to toggle the sidebar
  };

  const handleChange = (event) => {
    setSelectDropdownYear(event.target.value);
    localStorage.setItem("Year", event.target.value);
    setYearData(event.target.value);
    window.location.reload();
  };

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Get the current page title from navConfig based on the current path
  const currentPage = navConfig.find(item => item.path === location.pathname);
  const pageTitle = currentPage ? currentPage.title : 'Dashboard';

  return (
    <nav className="navbar navbar-cash navbar-expand-lg navbar-light border-bottom" style={{ zIndex: 99 }}>
      <div className="container-fluid">
        <img src={logo} alt="Logo" className="logo" style={{ marginLeft: '10px' }} />

        <span className="toggleIcon" id="sidebarToggle" onClick={handleToggleSidebar}>
          {isSidebarOpen ? <ArrowBackIcon /> : <MenuIcon />} {/* Toggle between MenuIcon and ArrowBackIcon */}
        </span>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className='ml-4 headingText'>{pageTitle}</div>
        <div className="collapse navbar-collapse align-items-end justify-content-end" id="navbarSupportedContent">
          <div className="search-tab align-items-end justify-content-end">
            <div className="align-items-end justify-content-end">
              <select
                className='inputbox'
                style={{ padding: '6px', marginRight: '10px' }}
                value={selectDropdownYear}
                onChange={handleChange}
              >
                <option value="">Change Financial Year</option>
                <option value="2029">Jan-2029 to Dec-2029</option>
                <option value="2028">Jan-2028 to Dec-2028</option>
                <option value="2027">Jan-2027 to Dec-2027</option>
                <option value="2026">Jan-2026 to Dec-2026</option>
                <option value="2025">Jan-2025 to Dec-2025</option>
                <option value="2024">Jan-2024 to Dec-2024</option>
                <option value="2023">Jan-2023 to Dec-2023</option>
              </select>
            </div>
          </div>
          <div className="logoutIcon" onClick={() => { localStorage.setItem("Year", new Date().getFullYear()); onLogout(); }} style={{ cursor: 'pointer', marginRight: '10px' }}></div>
          {/* <img src={logoutIcon} alt="logoutIcon"   /> */}
          <div className="logo-container" onClick={handleToggleDropdown} style={{ position: 'relative' }}>
            <img src={profileImage } alt="Profile" className="profile-img" style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '10px', border: 'none' }} />
            {isDropdownOpen && (
              <div>
                <ul className="dropdown-menu show" style={{ position: 'absolute', top: '50px', left: '-150px', zIndex: '1000', backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '4px', listStyle: 'none', padding: '6px' }}>
                  <li className="dropdown-item">
                    <NavLink to="/update-password" className="dropdown" onClick={() => setIsDropdownOpen(false)}>
                      Update Password
                    </NavLink>
                  </li>
                  <li className="dropdown-item">
                    <NavLink to="/update-email" className="dropdown" onClick={() => setIsDropdownOpen(false)}>
                      Update Email
                    </NavLink>
                  </li>
                  <li className="dropdown-item">
                    <NavLink to="/update-profile" className="dropdown" onClick={() => setIsDropdownOpen(false)}>
                      Update Profile
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
