import React from "react";
import FormSection from '../../components/Form/FormSection';


const UpdateUsername = () => {

    return (
        <>
            <div className='FormContainer'>
                <div className='container' >
                    <div className='row'>
                    <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>Update User Name</span>
                            </div>
                        <div className='col-lg-12'>
                            <div className='formHeadingSales'><label></label></div>
                            <div className='inputboxdiv'>
                                <input
                                    type='text'
                                    className='inputbox'
                                    placeholder='Enter New User Name'
                                    // onChange={(event) => handleInputChange(event, index)}
                                />
                                <input
                                    type='button'
                                    className='inputboxButton'
                                    value='Update'
                                // onClick={() => handleButtonClick(index)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
 export default UpdateUsername;