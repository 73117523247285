import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection';
import '../assets/css/Form.css';
import CustomTable from './Table/Table';
const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec","Sum"];
const MonthDrop = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const head = ["Gross Profit", ...Month];

function SalesInventories() {
    const [Year, setYear] = useState(localStorage.getItem("Year"));
    const [operatingExpenses, setOperatingExpenses] = useState(null);
    const CompanyId = localStorage.getItem('CompanyId');
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);
    const [DropdownProductList, setDropdownProductList] = useState([]);
    const [sectionsState, setSectionsState] = useState({
        selectedDropdownValues: [],
        selectedMonthValues: [],
        inputValues: [],
        selectedModalValues: [],
    });

    const [secondDropdown, setSecondDropdown] = useState([]);
    const [SalesInventInputValue, setSalesInventInputValue] = useState('');
    const [SalesInventDropdown, setSalesInventDropdown] = useState('');
    const [SelectedSecOptions, setSelectedSecOptions] = useState('');

    const fetchProducts = async () => {
        try {
            const url = `https://laravel.cashflow365.se/api/add_product?year=${Year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }

            const dataDropdown = data.products
                .filter(product => product && product.product_name !== undefined)
                .map((product, index) => ({
                    id: index + 1,
                    name: product.product_name,
                }));
            const arrayOfArrays = dataDropdown.map(row => [row.name]);
            setDropdownProductList(arrayOfArrays);
            setGetError(null);
            console.log(arrayOfArrays)
        } catch (error) {
            setGetError(error.message);
        }
    };

    const handleUpdate = async (dropdownValue, monthValue, amountValue, modalSelect) => {
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/update/calculate-sales-inv?year=${Year}&comp_id=${CompanyId}`;
            const requestBody = {
                model: modalSelect,
                dropdown: dropdownValue,
                month: monthValue,
                days: amountValue
            };
            await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            fetchData();
        } catch (error) {
            console.error('Error updating income tax data:', error);
        }
    };

    const handleInputChange = (event, sectionIndex) => {
        const newValue = event.target.value;
        setSectionsState(prevState => {
            const updatedInputValues = [...prevState.inputValues];
            updatedInputValues[sectionIndex] = newValue;
            return { ...prevState, inputValues: updatedInputValues };
        });
    };

    const handleButtonClick = (sectionIndex) => {
        const dropdownValue = sectionsState.selectedDropdownValues[sectionIndex];
        const monthValue = sectionsState.selectedMonthValues[sectionIndex];
        const amountValue = sectionsState.inputValues[sectionIndex];
        const modalSelect = sectionsState.selectedModalValues[sectionIndex];
        handleUpdate(dropdownValue, monthValue, amountValue, modalSelect);
        setSectionsState(prevState => {
            const updatedInputValues = [...prevState.inputValues];
            updatedInputValues[sectionIndex] = '';
            return { ...prevState, inputValues: updatedInputValues };
        });
    };

    const handleSelect1Change = (selectedOption, sectionIndex) => {
        const modalValues = [
            'sales',
            'Gross Margin',
            'Inventories',
            'sales_credit_days',
            'Account Payable Credit Days',
            'Sales Vat',
            'Purchase Vat'
        ];
        setSectionsState(prevState => {
            const updatedDropdownValues = [...prevState.selectedDropdownValues];
            updatedDropdownValues[sectionIndex] = selectedOption;
            const updatedModalValues = [...prevState.selectedModalValues];
            updatedModalValues[sectionIndex] = modalValues[sectionIndex];
            return {
                ...prevState,
                selectedDropdownValues: updatedDropdownValues,
                selectedModalValues: updatedModalValues,
            };
        });
    };

    const handleSelect2Change = (selectedOption, sectionIndex) => {
        setSectionsState(prevState => {
            const updatedMonthValues = [...prevState.selectedMonthValues];
            updatedMonthValues[sectionIndex] = selectedOption;
            return { ...prevState, selectedMonthValues: updatedMonthValues };
        });
    };

    const salesData = {
        monthlyHeadingText: 'Monthly Gross Profit Entry',
        formSections: [
            {
                heading: 'Sales',
                selectOptions1: DropdownProductList.map((item) => ({
                    value: item,
                    label: item
                })),
                selectOptions2: MonthDrop.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
            {
                heading: 'Gross Margin %',
                selectOptions1: DropdownProductList.map((item) => ({
                    value: item,
                    label: item
                })),
                selectOptions2: MonthDrop.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
            {
                heading: 'Inventories',
                selectOptions1: DropdownProductList.map((item, index) => ({
                    value: item,
                    label: item
                })),
                selectOptions2: MonthDrop.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
            {
                heading: 'Sales Credit Days',
                selectOptions1: [
                    { value: 'Days', label: 'Days' },
                ],
                selectOptions2: MonthDrop.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
            {
                heading: 'Account Payable Credit Days',
                selectOptions1: [
                    { value: 'Days', label: 'Days' },
                ],
                selectOptions2: MonthDrop.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Number Of Days',
                buttonText: 'Update',
                type: 'number'
            },
            {
                heading: 'Sales Vat %',
                selectOptions1: [
                    { value: 'Vat %', label: 'Vat %' },
                ],
                selectOptions2: MonthDrop.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Vat %',
                buttonText: 'Update',
                type: 'number'
            },
            {
                heading: 'Purchase Vat %',
                selectOptions1: [
                    { value: 'Vat %', label: 'Vat %' },
                ],
                selectOptions2: MonthDrop.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Vat %',
                buttonText: 'Update',
                type: 'number'
            },
        ],
    };

    const fetchData = async () => {
        setGetLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/calculate-sales-inv?year=${Year}&comp_id=${CompanyId}`;
            console.log(url)
            const response = await fetch(url);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }
            setOperatingExpenses(data);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
        fetchData();
    }, [Year]);

    useEffect(() => {
        const handleStorageChange = () => {
            setYear(localStorage.getItem("Year"));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const generateRows = () => {
        if (!operatingExpenses) return [];
    
        const sections = {
            'Sales': 'Sales',
            'Gross Margin %': 'Gross Margin %',
            'Cost of Sold Goods': 'Cost of Sold Goods',
            'Inventories': 'Inventories',
            'AccountPayable': 'Account Payable'
        };
    
        const rows = [];
    
        Object.keys(sections).forEach((key) => {
            const sectionHeading = sections[key];
            const sectionData = operatingExpenses[key];
            if (sectionData) {
                rows.push([sectionHeading]);
    
                // Handle Inventories section with Beginning Month Balance first
                if (key === 'Inventories') {
                    const beginningBalance = sectionData["Beginning Month Balance"];
                    if (beginningBalance) {
                        const monthlyValues = Array.from({ length: 13 }, (_, i) => (beginningBalance[(i + 1).toString()] ));
                        rows.push(["Beginning Month Balance", ...monthlyValues]);
                    }
                }
    
                // Handle other sub-keys
                Object.keys(sectionData).forEach((subKey) => {
                    if (key !== 'Inventories' || subKey !== "Beginning Month Balance") {
                        const monthlyValues = Array.from({ length: 13 }, (_, i) => (sectionData[subKey][(i + 1).toString()] ));
                        rows.push([subKey, ...monthlyValues]);
                    }
                });
    
                rows.push([]);
            }
        });
    
        return rows;
    };
    
    

    const rows = generateRows();

    const handleOptionAuto = (selectedOption, sectionIndex) => {
        console.log("selectedOption " + selectedOption);

        if (selectedOption === "Sales") {
            setSecondDropdown((DropdownProductList || []).map(item => ({
                value: item,
                label: item
            })));
        } else if (selectedOption === "Gross Margin") {
            setSecondDropdown((DropdownProductList || []).map(item => ({
                value: item,
                label: item
            })));
        } else if (selectedOption === "Inventories") {
            setSecondDropdown((DropdownProductList || []).map(item => ({
                value: item,
                label: item
            })));
        } else if (selectedOption === "Sales Credit Days") {
            setSecondDropdown([
                { value: 'Days', label: 'Days' },
            ]);
        } else if (selectedOption === "Account Payable Credit Days") {
            setSecondDropdown([
                { value: 'Days', label: 'Days' },
            ]);
        } else if (selectedOption === "Sales Vat") {
            setSecondDropdown([
                { value: 'Vat %', label: 'Vat %' },
            ]);
        } else if (selectedOption === "Purchase Vat") {
            setSecondDropdown([
                { value: 'Vat %', label: 'Vat %' },
            ]);
        }
        setSalesInventDropdown(selectedOption);
        console.log(secondDropdown);
    };

    const sectionStatic = [
        'Sales',
        'Gross Margin',
        'Inventories',
        'Sales Credit Days',
        'Account Payable Credit Days',
        'Sales Vat',
        'Purchase Vat'
    ];

    const Ai = {
        monthlyHeadingText: 'Automation Entry',
        formSections: [
            {
                heading: '',
                selectOptions1: sectionStatic.map((index, item) => ({
                    value: index,
                    label: index
                })),
                selectOptions2: secondDropdown, // Initially empty
                inputPlaceholder: 'Amount/Days',
                buttonText: 'Start Automation',
                type: 'number'
            },
        ],
    };

    const handleSalesInventInputChange = (event) => {
        const newValue = event.target.value;
        setSalesInventInputValue(newValue);
        console.log(`Automation Input Value: ${newValue}`);
    };

    const handleSelectDebtChange = (selectedOption, sectionIndex) => {
        setSelectedSecOptions(selectedOption);
    };

    const handleSalesInventButtonClick = async () => {
        const dropdownValue = SalesInventDropdown;
        const dropdownSecValue = SelectedSecOptions;
        const amountValue = SalesInventInputValue;

        handleSalesInventTaxUpdate(dropdownValue, dropdownSecValue, amountValue);
        
    };

    const handleSalesInventTaxUpdate = async (dropdownValue, dropdownSecValue, amountValue) => {
        setGetLoading(true);
        setSalesInventInputValue('')
        console.log(
            `Dropdown Value: ${dropdownValue}, Dropdown Sec Value: ${dropdownSecValue}, Amount Value ${amountValue}`
        )
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/automation?year=${Year}&comp_id=${CompanyId}`
            const requestBody = {
                dropdown: dropdownSecValue,
                module: "salesInventories",
                subModule: dropdownValue,
                value: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error updating equity:', error);
        }
    };

    if (getLoading) return <div className='loader-container'>
        <div class="loader"></div>
    </div>;
    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                productAndServicesLabel="Product and Services"
                                selectPlaceholder="Select Value"
                                handleButtonClick={() => handleSalesInventButtonClick()}
                                handleSelect1Change={handleOptionAuto}
                                handleSelect2Change={handleSelectDebtChange}
                                handleInputChange={(event) => handleSalesInventInputChange(event)}
                                inputValue={SalesInventInputValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={salesData.formSections}
                                productAndServicesLabel="Select type Details"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleButtonClick}
                                handleInputChange={handleInputChange}
                                handleSelect1Change={handleSelect1Change}
                                handleSelect2Change={handleSelect2Change}
                                inputValues={sectionsState.inputValues}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <CustomTable head={head} rows={rows} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalesInventories;
