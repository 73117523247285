import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection';
import CustomTable from './Table/Table';
import Api from '../api/Api'; // Adjust the path based on your project structure

const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const head = ["Fixed Assets", ...Month];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]; // Assuming these are your month numbers

function FixedAssets() {
    const [fixedAssetsData, setFixedAssetsData] = useState(null);
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    const [DropdownAutomationdata, setDropdownAutomationdata] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState('');
    const [EquipmentDropdown, setEquipmentDropdown] = useState(null);
    const [LongTermInvestments, setLongTermInvestments] = useState(null);
    const [secondDropdown, setsecondDropdown] = useState([]);
    const [FixedAssestsInputValue, setFixedAssestsInputValue] = useState('');
    const [FixedAssestsDropdown, setFixedAssestsDropdown] = useState('');
    const [SelectedSecOptions, setSelectedSecOptions] = useState('');
    const [sectionsState, setSectionsState] = useState({
        inputValues: [],
        selectedDropdownValues: [],
        selectedMonthValues: [],
        selectedModalValues: []
    });
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);
    const [getLoading1, setGetLoading1] = useState(false);
    const [year, setYear] = useState(localStorage.getItem("Year"));

    const { response: responseAutomation, loading: loadingAutomation, error: errorAutomation } = Api({
        modal: 'monthly_fixed_assets_entry',
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET'
    });

    useEffect(() => {
        if (loadingAutomation) {
            console.log('Loading accrued expenses errorAutomation...');
        }
        if (errorAutomation) {
            console.error('Error fetching accrued expenses:', errorAutomation.message);
        }
        if (responseAutomation) {
            console.log('Automation fetched successfully:', responseAutomation);
            const data = Object.keys(responseAutomation);
            const AutomationDropdown = data.map(item => ({
                value: item,
                label: item
            }));
            setEquipmentDropdown(responseAutomation.equipment);
            setLongTermInvestments(responseAutomation.longTermInvestment);
            setDropdownAutomationdata(responseAutomation);
        }
    }, [responseAutomation, loadingAutomation, errorAutomation]);

    const handleOption1Change = (selectedOption) => {
        setSelectedOption1(selectedOption);
        console.log('handleOption1Change' + selectedOption);
    };

    const getSelectOptions2 = () => {
        if (selectedOption1 && DropdownAutomationdata) {
            console.log('selectedOption1' + selectedOption1);
            return DropdownAutomationdata[selectedOption1].map(item => ({
                value: item,
                label: item
            }));
        }
        return [];
    };

    const sections2 = [
        'Equipment',
        'Long Term Investment'
    ];

    const salesData = {
        monthlyHeadingText: 'Monthly Fixed Assets Entry',
        formSections: [
            {
                heading: 'Equipment',
                selectOptions1: (EquipmentDropdown || []).map(item => ({
                    value: item,
                    label: item
                })),
                selectOptions2: Month.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
            {
                heading: 'Long Term Investments',
                selectOptions1: (LongTermInvestments || []).map(item => ({
                    value: item,
                    label: item
                })),
                selectOptions2: Month.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
        ],
    };

    const fetchData = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/calculate-fixed-assets?year=${year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }
            setFixedAssetsData(data);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading1(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [year]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "Year") {
                setYear(event.newValue);
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const handleUpdate = async (dropdownValue, monthValue, amountValue, modalSelect) => {
        console.log('dropdownValue' + dropdownValue);
        console.log('monthValue' + monthValue);
        console.log('amountValue' + amountValue);
        console.log('modalSelect' + modalSelect);
        setGetLoading(true);
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/update/calculate-fixed-assets?year=${year}&comp_id=${CompanyId}`;
            const requestBody = {
                dropdownmodel: modalSelect,
                dropdowntype: dropdownValue,
                month: monthValue,
                Amount: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error updating fixed assets data:', error);
        }
    };

    const handleInputChange = (event, sectionIndex) => {
        const newValue = event.target.value;
        setSectionsState(prevState => {
            const updatedInputValues = [...prevState.inputValues];
            updatedInputValues[sectionIndex] = newValue;
            return { ...prevState, inputValues: updatedInputValues };
        });
    };

    const handleButtonClick = async (sectionIndex) => {
        const dropdownValue = sectionsState.selectedDropdownValues[sectionIndex];
        const monthValue = sectionsState.selectedMonthValues[sectionIndex];
        const amountValue = sectionsState.inputValues[sectionIndex];
        const modalSelect = sectionsState.selectedModalValues[sectionIndex];
        console.log(`dropdownValue: ${dropdownValue}`);
        console.log(`amountValue: ${amountValue}`);
        handleUpdate(dropdownValue, monthValue, amountValue, modalSelect);
        setSectionsState(prevState => {
            const updatedInputValues = [...prevState.inputValues];
            updatedInputValues[sectionIndex] = '';
            return { ...prevState, inputValues: updatedInputValues };
        });
    };

    const handleSelect1Change = (selectedOption, sectionIndex) => {
        if (sectionIndex === 0) {
            setSectionsState(prevState => ({
                ...prevState,
                selectedModalValues: { ...prevState.selectedModalValues, [sectionIndex]: 'equipment' }
            }));
        }
        if (sectionIndex === 1) {
            setSectionsState(prevState => ({
                ...prevState,
                selectedModalValues: { ...prevState.selectedModalValues, [sectionIndex]: 'longTermInvestment' }
            }));
        }
        setSectionsState(prevState => ({
            ...prevState,
            selectedDropdownValues: { ...prevState.selectedDropdownValues, [sectionIndex]: selectedOption }
        }));
    };

    const handleSelect2Change = (selectedOption, sectionIndex) => {
        setSectionsState(prevState => ({
            ...prevState,
            selectedMonthValues: { ...prevState.selectedMonthValues, [sectionIndex]: selectedOption }
        }));
    };

    const rows = [
        ['Equipment', ''],
        ['Beginning Month Balance', ...months.map(month => fixedAssetsData?.equip_beg_mont_bal[month] || '0')],
        ['Purchase of Property', ...months.map(month => fixedAssetsData?.pur_of_prop[month] || '0')],
        ['Sales of Equipment', ...months.map(month => fixedAssetsData?.sales_equip[month] || '0')],
        ['Depreciation', ...months.map(month => fixedAssetsData?.equip_deprec[month] || '0')],
        ['End of Month Balance', ...months.map(month => fixedAssetsData?.equip_end_bal[month] || '0')],
        ['Long Term Investments', ''],
        ['Beginning Month Balance', ...months.map(month => fixedAssetsData?.long_trm_beg_mont_bal[month] || '0')],
        ['Purchase of Investments', ...months.map(month => fixedAssetsData?.pur_of_invest[month] || '0')],
        ['Sales of Investments', ...months.map(month => fixedAssetsData?.sales_of_invest[month] || '0')],
        ['Depreciation', ...months.map(month => fixedAssetsData?.long_trm_deprec[month] || '0')],
        ['End of Month Balance', ...months.map(month => fixedAssetsData?.long_end_bal[month] || '0')],
    ];

    const handleOptionAuto = (selectedOption, sectionIndex) => {
        console.log("selectedOption " + selectedOption)

        if (selectedOption === "equipment") {
            setsecondDropdown((EquipmentDropdown || []).map(item => ({
                value: item,
                label: item
            })));
        }
        else if (selectedOption === "longTermInvestment") {
            setsecondDropdown((LongTermInvestments || []).map(item => ({
                value: item,
                label: item
            })));
        }
        setFixedAssestsDropdown(selectedOption);
        console.log(secondDropdown);
    };

    const Ai = {
        monthlyHeadingText: 'Automation Entry',
        formSections: [
            {
                heading: '',
                selectOptions1: Object.keys(DropdownAutomationdata || {}).map((item, index) => ({
                    value: item,
                    label: sections2[index]
                })),
                selectOptions2: secondDropdown, // Initially empty
                inputPlaceholder: 'Amount',
                buttonText: 'Start Automation',
                type: 'number'
            },
        ],
    };

    const handleFixedAssestsInputChange = (event) => {
        const newValue = event.target.value;
        setFixedAssestsInputValue(newValue);
        console.log(`Automation Input Value: ${newValue}`);
        // Perform any additional logic here
    };

    const handleSelectDebtChange = (selectedOption, sectionIndex) => {
        setSelectedSecOptions(selectedOption);
    };

    const handleFixedAssestsButtonClick = async () => {
        const dropdownValue = FixedAssestsDropdown;
        const dropdownSecValue = SelectedSecOptions;
        const amountValue = FixedAssestsInputValue;

        handleFixedAssestsTaxUpdate(dropdownValue, dropdownSecValue, amountValue);
        setFixedAssestsInputValue('');
    };

    const handleFixedAssestsTaxUpdate = async (dropdownValue, dropdownSecValue, amountValue) => {
        setGetLoading(true);
        if (dropdownValue === 'equipment') {
            dropdownValue = "Equipment";
        } else if (dropdownValue === 'longTermInvestment') {
            dropdownValue = "Long Term Investments";
        }
        console.log(
            `Dropdown Value: ${dropdownValue}, Dropdown Sec Value: ${dropdownSecValue}, Amount Value ${amountValue}`
        );
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/automation?year=${year}&comp_id=${CompanyId}`;
            const requestBody = {
                dropdown: dropdownSecValue,
                module: "fixedAssets",
                subModule: dropdownValue,
                value: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error updating equity:', error);
        }
    };

    if (getLoading) return <div className='loader-container'>
    <div class="loader"></div>
</div>;
    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                productAndServicesLabel="Product and Services"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleFixedAssestsButtonClick}
                                handleSelect1Change={handleOptionAuto}
                                handleSelect2Change={handleSelectDebtChange}
                                handleInputChange={handleFixedAssestsInputChange}
                                inputValue={FixedAssestsInputValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={salesData.formSections}
                                productAndServicesLabel="Select type Details"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleButtonClick}
                                handleInputChange={handleInputChange}
                                handleSelect1Change={handleSelect1Change}
                                handleSelect2Change={handleSelect2Change}
                                inputValues={sectionsState.inputValues}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        {fixedAssetsData && Array.isArray(rows) && rows.length > 0 && (
                            <CustomTable head={head} rows={rows} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FixedAssets;
