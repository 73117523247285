import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection';
import CustomTable from './Table/Table';
import '../assets/css/Form.css';
import Api from '../api/Api';
import { getYearData } from './demo';

const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const head = ["Other Current Assets", ...Month];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

function OtherCurrentAssets() {
  const [year, setYear] = useState(localStorage.getItem('Year'));
  const [otherCurrantAssets, setOtherCurrantAssets] = useState(null);
  const Userid = localStorage.getItem('UserName'); 
  const CompanyId = localStorage.getItem('CompanyId');
  const [DropdownAutomationdata, setDropdownAutomationdata] = useState(null);
  const [inputValues, setInputValues] = useState(null);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
  const [selectedMonthValue, setSelectedMonthValue] = useState('');
  const [getLoading, setGetLoading] = useState(false);
  const [OtherCurrAssetsInputValue, setOtherCurrAssetsInputValue] = useState('');
  const [OtherCurrAssetsDropdown, setOtherCurrAssetsDropdown] = useState('');
  const fetchedData = getYearData();
  console.log(fetchedData+'maha')
  useEffect(() => {
    const handleStorageChange = async(event) => {
      if (event.key === 'Year') {
        console.log("event.newValue"+event.newValue)
        setYear(event.newValue);
        setGetLoading(true); 
        await fetchData();
        setTimeout(() => {
          setGetLoading(false); // Set loading to false after a delay (e.g., 1000ms)
        }, 5000); // Adjust the delay time as needed
      }
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [year]);
  

  useEffect(() => {
    fetchData();
  }, [year]);

  const handleUpdate = async (dropdownValue, monthValue, amountValue) => {
    setGetLoading(true);
    try {
      const apiUrl = `https://laravel.cashflow365.se/api/data/update_other_curr_assetss?year=${year}&comp_id=${CompanyId}`;
      const requestBody = {
        dropdownname: dropdownValue,
        month: monthValue,
        Amount: amountValue
      };
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      console.log('Update response:', data);
      setGetLoading(false);
      fetchData();
    } catch (error) {
      console.error('Error updating OtherCurrAssets expenses:', error);
    }
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValues(newValue);
  };

  const handleButtonClick = async () => {
    const dropdownValue = selectedDropdownValue;
    const monthValue = selectedMonthValue;
    const amountValue = inputValues;
    handleUpdate(dropdownValue, monthValue, amountValue);
    setInputValues(amountValue);
  };

  const handleSelect1Change = (selectedOption) => {
    setSelectedDropdownValue(selectedOption);
  };

  const handleSelect2Change = (selectedOption) => {
    setSelectedMonthValue(selectedOption);
  };

  const { response: responseAutomation, loading: loadingAutomation, error: errorAutomation } = Api({
    modal: 'other_current_asset',
    UserId: Userid,
    CompanyId: CompanyId,
    method: 'GET'
  });

  useEffect(() => {
    if (loadingAutomation) {
      console.log('Loading OtherCurrAssets expenses...');
    }
    if (errorAutomation) {
      console.error('Error fetching OtherCurrAssets expenses:', errorAutomation.message);
    }
    if (responseAutomation) {
      const formattedData = responseAutomation.map(item => ({
        value: item,
        label: item
      }));
      setDropdownAutomationdata(formattedData);
    }
  }, [responseAutomation, loadingAutomation, errorAutomation]);

  const Ai = {
    monthlyHeadingText: 'Automation Entry',
    formSections: [
      {
        heading: '',
        selectOptions1: DropdownAutomationdata || [],
        inputPlaceholder: 'Amount',
        buttonText: 'Start Automation',
        type: 'number'
      },
    ],
  };

  const salesData = {
    monthlyHeadingText: 'Monthly Other Current Assets Entry',
    formSections: [
      {
        heading: '',
        selectOptions1: DropdownAutomationdata || [],
        selectOptions2: Month.map((item, index) => ({
          value: index + 1,
          label: item
        })),
        inputPlaceholder: 'Amount',
        buttonText: 'Update',
        type: 'number'
      },
    ],
  };

  const fetchData = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const url = `${baseUrl}/calculate-other-curr-assets?year=${year}&comp_id=${CompanyId}`;
      const response = await fetch(url);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
      setOtherCurrantAssets(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setGetLoading(false);
    }
  };

  const rows = otherCurrantAssets
    ? Object.keys(otherCurrantAssets).map((key) => [
      key,
      ...months.map((month) => otherCurrantAssets[key][month] || 0),
    ])
    : [];

  const handleOtherCurrAssetsInputChange = (event) => {
    const newValue = event.target.value;
    setOtherCurrAssetsInputValue(newValue);
    console.log(`Automation Input Value: ${newValue}`);
  };

  const handleOtherCurrAssetsSelect1Change = (selectedOption) => {
    setOtherCurrAssetsDropdown(selectedOption);
  };

  const handleOtherCurrAssetsButtonClick = async () => {
    const dropdownValue = OtherCurrAssetsDropdown;
    const amountValue = OtherCurrAssetsInputValue;
    handleOtherCurrAssetsTaxUpdate(dropdownValue, amountValue);
    setOtherCurrAssetsInputValue('');
  };

  const handleOtherCurrAssetsTaxUpdate = async (dropdownValue, amountValue) => {
    setGetLoading(true);
    try {
      const apiUrl = `https://laravel.cashflow365.se/api/automation?year=${year}&comp_id=${CompanyId}`;
      const requestBody = {
        dropdown: dropdownValue,
        module: "otherCurrentAssets",
        subModule: "",
        value: amountValue
      };
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      console.log('Update response:', data);
      setGetLoading(false);
      fetchData();
    } catch (error) {
      console.error('Error updating equity:', error);
    }
  };

  if (getLoading) return <div className='loader-container'>
        <div class="loader"></div>
    </div>;

  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='formBoxMonthly'>
              <div className='MonthlyHeading p-2'>
                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
              </div>
              <FormSection
                sections={Ai.formSections}
                productAndServicesLabel="Product and Services"
                selectPlaceholder="Select Value"
                handleButtonClick={() => handleOtherCurrAssetsButtonClick()}
                handleSelect1Change={handleOtherCurrAssetsSelect1Change}
                handleInputChange={(event) => handleOtherCurrAssetsInputChange(event)}
                inputValue={OtherCurrAssetsInputValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='formBoxMonthly'>
              <div className='MonthlyHeading p-2'>
                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
              </div>
              <FormSection
                sections={salesData.formSections}
                productAndServicesLabel="Select type Details"
                selectPlaceholder="Select Value"
                handleButtonClick={handleButtonClick}
                handleInputChange={handleInputChange}
                handleSelect1Change={handleSelect1Change}
                handleSelect2Change={handleSelect2Change}
                inputValues={inputValues}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-lg-12 '>
            {otherCurrantAssets && (
              <CustomTable head={head} rows={rows} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherCurrentAssets;
