import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; 
import '../assets/css/Form.css';
import CustomTable from './Table/Table';
import Api from '../api/Api';

const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const head = ["Accrued Expenses", ...Month];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

function AccruedExpenses() {
    const [year, setYear] = useState(localStorage.getItem("Year"));
    const [accruedExpenses, setAccruedExpenses] = useState(null);
    const [DropdownAutomationdata, setDropdownAutomationdata] = useState(null);
    const [inputValues, setInputValues] = useState(null);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
    const [selectedMonthValue, setSelectedMonthValue] = useState('');
    const [getLoading, setGetLoading] = useState(false);
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    const [AccruedInputValue, setAccruedInputValue] = useState('');
    const [AccruedDropdown, setAccruedDropdown] = useState('');

    useEffect(() => {
        const handleStorageChange = () => {
                console.log("maha"+year)
                setYear(prevyear =>localStorage.getItem("Year"));
                console.log(year+"Year")
        };
        handleStorageChange()
        // window.addEventListener("storage", handleStorageChange);
        // return () => {
        //     window.removeEventListener("storage", handleStorageChange);
        // };
    }, []);

    const { response: responseAutomation, loading: loadingAutomation, error: errorAutomation } = Api({
        modal: 'monthly_accrued_expenses_entrydropdown',
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET'
    });

    useEffect(() => {
        if (loadingAutomation) {
            console.log('Loading accrued expenses...');
        }
        if (errorAutomation) {
            console.error('Error fetching accrued expenses:', errorAutomation.message);
        }
        if (responseAutomation) {
            console.log('Automation fetched successfully:', responseAutomation.data);
            const formattedData = responseAutomation.data.map(item => ({
                value: item,
                label: item
            }));
            setDropdownAutomationdata(formattedData); // Store formatted API response in state
        }
    }, [responseAutomation, loadingAutomation, errorAutomation]);

    const Ai = {
        monthlyHeadingText: 'Automation Entry',
        formSections: [
            {
                heading: '',
                selectOptions1: DropdownAutomationdata || [],
                inputPlaceholder: 'Amount',
                buttonText: 'Start Automation',
                type: 'number'
            },
        ],
    };
        
    const salesData = {
        monthlyHeadingText: 'Monthly Accrued Expenses Entry',
        formSections: [
            {
                heading: '',
                selectOptions1: DropdownAutomationdata || [],
                selectOptions2: Month.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
        ],
    };

    const [getLoading1, setGetLoading1] = useState(false);
    const [getError, setGetError] = useState(null);
    const fetchData = async () => {
        setGetLoading1(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/calculate-accr-exp?year=${year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }
            setAccruedExpenses(data);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading1(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [year]);

    const handleUpdate = async (dropdownValue, monthValue, amountValue) => {
        setGetLoading(true);
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/update/accruednew?year=${year}&comp_id=${CompanyId}`;
            const requestBody = {
                AccruedName: dropdownValue,
                month: monthValue,
                Amount: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error updating accrued expenses:', error);
        }
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputValues(newValue);
    };

    const handleButtonClick = async () => {
        const dropdownValue = selectedDropdownValue;
        const monthValue = selectedMonthValue;
        const amountValue = inputValues;
        handleUpdate(dropdownValue, monthValue, amountValue);
    };

    const handleSelect1Change = (selectedOption) => {
        setSelectedDropdownValue(selectedOption);
    };

    const handleSelect2Change = (selectedOption) => {
        setSelectedMonthValue(selectedOption);
    };

    const rows = accruedExpenses
        ? Object.keys(accruedExpenses).map((key) => [
            key,
            ...months.map((month) => accruedExpenses[key][month] || 0),
        ])
        : [];

    const handleAccruedInputChange = (event) => {
        const newValue = event.target.value;
        setAccruedInputValue(newValue);
        console.log(`Automation Input Value: ${newValue}`);
    };

    const handleAccruedSelect1Change = (selectedOption) => {
        setAccruedDropdown(selectedOption);
    };

    const handleAccruedButtonClick = async () => {
        const dropdownValue = AccruedDropdown;
        const amountValue = AccruedInputValue;
        handleAccruedTaxUpdate(dropdownValue, amountValue);
        setAccruedInputValue('');
    };

    const handleAccruedTaxUpdate = async (dropdownValue, amountValue) => {
        setGetLoading(true);
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/automation?year=${year}&comp_id=${CompanyId}`;
            const requestBody = {
                dropdown: dropdownValue,
                module: "accruedExpenses",
                subModule: "",
                value: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error updating equity:', error);
        }
    };

    if (getLoading) return <div className='loader-container'>
        <div class="loader"></div>
    </div>;

    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                productAndServicesLabel="Product and Services"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleAccruedButtonClick}
                                handleSelect1Change={handleAccruedSelect1Change}
                                handleInputChange={handleAccruedInputChange}
                                inputValue={AccruedInputValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={salesData.formSections}
                                productAndServicesLabel="Select type Details"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleButtonClick}
                                handleInputChange={handleInputChange}
                                handleSelect1Change={handleSelect1Change}
                                handleSelect2Change={handleSelect2Change}
                                inputValues={inputValues}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        <CustomTable head={head} rows={rows} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccruedExpenses;
