import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table';
import Api from '../api/Api';

const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const head = ["Operating Expenses", ...Month, "Sum"];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

function OperatingExpenses() {
    const [year, setYear] = useState(localStorage.getItem("Year") || '');
    const [operatingExpenses, setOperatingExpenses] = useState(null);
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    const [DropdownAutomationdata, setDropdownAutomationdata] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState('');
    const [EquipmentDropdown, setEquipmentDropdown] = useState(null);
    const [LongTermInvestments, setLongTermInvestments] = useState(null);
    const [sectionsState, setSectionsState] = useState({
        inputValues: [],
        selectedDropdownValues: [],
        selectedMonthValues: [],
        selectedModalValues: []
    });
    const [getLoading, setGetLoading] = useState(false);
    const [getError, setGetError] = useState(null);
    const [getLoading1, setGetLoading1] = useState(false);

    const [secondDropdown, setSecondDropdown] = useState([]);
    const [OpertExpenInputValue, setOpertExpenInputValue] = useState('');
    const [OpertExpenDropdown, setOpertExpenDropdown] = useState('');
    const [SelectedSecOptions, setSelectedSecOptions] = useState('');

    const fetchData = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const url = `${baseUrl}/calculate-oper-exp?year=${year}&comp_id=${CompanyId}`;
            const response = await fetch(url);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
            }
            setOperatingExpenses(data);
            setGetError(null);
        } catch (error) {
            setGetError(error.message);
        } finally {
            setGetLoading1(false);
        }
    };

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'Year') {
                setYear(event.newValue);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, [year]);

    const handleUpdate = async (dropdownValue, monthValue, amountValue, modalSelect) => {
        console.log('dropdownValue', dropdownValue);
        console.log('monthValue', monthValue);
        console.log('amountValue', amountValue);
        console.log('modalSelect', modalSelect);
        setGetLoading(true);
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/update/add_other_opr_exp?year=${year}&comp_id=${CompanyId}`;
            const requestBody = {
                dropdownmodelname: modalSelect,
                dropdown: dropdownValue,
                month: monthValue,
                Amount: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error updating income tax data:', error);
        }
    };

    const handleInputChange = (event, sectionIndex) => {
        const newValue = event.target.value;
        setSectionsState(prevState => {
            const updatedInputValues = [...prevState.inputValues];
            updatedInputValues[sectionIndex] = newValue;
            return { ...prevState, inputValues: updatedInputValues };
        });
    };

    const handleButtonClick = async (sectionIndex) => {
        const dropdownValue = sectionsState.selectedDropdownValues[sectionIndex];
        const monthValue = sectionsState.selectedMonthValues[sectionIndex];
        const amountValue = sectionsState.inputValues[sectionIndex];
        const modalSelect = sectionsState.selectedModalValues[sectionIndex];
        console.log(`dropdownValue: ${dropdownValue}`);
        console.log(`amountValue: ${amountValue}`);
        handleUpdate(dropdownValue, monthValue, amountValue, modalSelect);
        setSectionsState(prevState => {
            const updatedInputValues = [...prevState.inputValues];
            updatedInputValues[sectionIndex] = '';
            return { ...prevState, inputValues: updatedInputValues };
        });
    };

    const handleSelect1Change = (selectedOption, sectionIndex) => {
        const modalValues = ['SalariesWages', 'othroprexpensedetails'];
        setSectionsState(prevState => {
            const updatedDropdownValues = [...prevState.selectedDropdownValues];
            updatedDropdownValues[sectionIndex] = selectedOption;
            const updatedModalValues = [...prevState.selectedModalValues];
            updatedModalValues[sectionIndex] = modalValues[sectionIndex];
            return {
                ...prevState,
                selectedDropdownValues: updatedDropdownValues,
                selectedModalValues: updatedModalValues,
            };
        });
    };

    const handleSelect2Change = (selectedOption, sectionIndex) => {
        setSectionsState(prevState => {
            const updatedMonthValues = [...prevState.selectedMonthValues];
            updatedMonthValues[sectionIndex] = selectedOption;
            return { ...prevState, selectedMonthValues: updatedMonthValues };
        });
    };

    const { response: responseAutomation, loading: loadingAutomation, error: errorAutomation } = Api({
        modal: 'other_operating_expense',
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET'
    });

    useEffect(() => {
        if (loadingAutomation) {
            console.log('Loading accrued expenses...');
        }
        if (errorAutomation) {
            console.error('Error fetching accrued expenses:', errorAutomation.message);
        }
        if (responseAutomation) {
            console.log('Automation fetched successfully:', responseAutomation);
            const data = Object.keys(responseAutomation);
            const AutomationDropdown = data.map(item => ({
                value: item,
                label: item
            }));
            setEquipmentDropdown(responseAutomation.SalariesWages);
            setLongTermInvestments(responseAutomation.othroprexpensedetails);
            setDropdownAutomationdata(responseAutomation);  
        }
    }, [responseAutomation, loadingAutomation, errorAutomation]);

    const handleOption1Change = (selectedOption) => {
        setSelectedOption1(selectedOption);
        console.log('handleOption1Change', selectedOption);
    };

    const getSelectOptions2 = () => {
        if (selectedOption1 && DropdownAutomationdata) {
            return DropdownAutomationdata[selectedOption1].map(item => ({
                value: item,
                label: item
            }));
        }
        return [];
    };
    
    const sections2 = [
        'Salaries & Wages',
        'Other Operating Expenses'
    ];
   
    
    const salesData = {
        monthlyHeadingText: 'Monthly Operating Expenses Entry',
        formSections: [
            {
                heading: 'Salaries & Wages',
                selectOptions1: (EquipmentDropdown || []).map(item => ({
                    value: item,
                    label: item
                })),
                selectOptions2: Month.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
            {
                heading: 'Other Operating Expenses',
                selectOptions1: (LongTermInvestments || []).map(item => ({
                    value: item,
                    label: item
                })),
                selectOptions2: Month.map((item, index) => ({
                    value: index + 1,
                    label: item
                })),
                inputPlaceholder: 'Amount',
                buttonText: 'Update',
                type: 'number'
            },
        ],
    };

    const calculateSum = (data) => {
        if (!data) return 0;
        return months.reduce((sum, month) => sum + parseInt(data[month] || '0', 10), 0);
    };
    
    const generateRows = () => {
        if (!operatingExpenses) return [];
    
        const sections = {
            'salary_wages': 'Salaries & Wages',
            'other_expenses': 'Other Operating Expenses'
        };
    
        const rows = [];
    
        Object.keys(sections).forEach((key) => {
            const sectionHeading = sections[key];
            const sectionData = operatingExpenses[key];
    
            if (sectionData) {
                rows.push([sectionHeading]);
    
                Object.keys(sectionData).forEach((subKey) => {
                    if (subKey !== 'Total') {
                        rows.push([
                            subKey,
                            ...months.map(month => parseInt(sectionData[subKey][month] || '0', 10)),
                            calculateSum(sectionData[subKey])
                        ]);
                    }
                });
    
                rows.push([]);
                rows.push([
                    'Total',
                    ...months.map(month => parseInt(sectionData['Total'][month] || '0', 10)),
                    calculateSum(sectionData['Total'])
                ]);
            }
        });
    
        // Calculate final totals
        const finalTotals = months.reduce((acc, month) => {
            acc[month] = 0;
            return acc;
        }, {});
    
        Object.keys(sections).forEach((key) => {
            const sectionData = operatingExpenses[key];
            if (sectionData) {
                months.forEach(month => {
                    finalTotals[month] += parseInt(sectionData['Total'][month] || '0', 10);
                });
            }
        });
    
        return rows;
    };

    const rows = generateRows();

    const handleOptionAuto = (selectedOption, sectionIndex) => {
        console.log("selectedOption " + selectedOption);
        if (selectedOption === "SalariesWages") {
            setSecondDropdown((EquipmentDropdown || []).map(item => ({
                value: item,
                label: item
            })));
        } else if (selectedOption === "othroprexpensedetails") {
            setSecondDropdown((LongTermInvestments || []).map(item => ({
                value: item,
                label: item
            })));
        }
        setOpertExpenDropdown(selectedOption);
        console.log(secondDropdown);
    };
 
    const Ai = {
        monthlyHeadingText: 'Automation Entry',
        formSections: [
            {
                heading: '',
                selectOptions1: Object.keys(DropdownAutomationdata || {}).map((item, index) => ({
                    value: item,
                    label: sections2[index]
                })),
                selectOptions2: secondDropdown, // Initially empty
                inputPlaceholder: 'Amount',
                buttonText: 'Start Automation',
                type: 'number'
            },
        ],
    };
 
    const handleOpertExpenInputChange = (event) => {
        const newValue = event.target.value;
        setOpertExpenInputValue(newValue);
        console.log(`Automation Input Value: ${newValue}`);
        // Perform any additional logic here
    };
    
    const handleSelectDebtChange = (selectedOption, sectionIndex) => {
        setSelectedSecOptions(selectedOption);
    };

    const handleOpertExpenButtonClick = async () => {
        const dropdownValue = OpertExpenDropdown;
        const dropdownSecValue = SelectedSecOptions;
        const amountValue = OpertExpenInputValue;

        handleOpertExpenTaxUpdate(dropdownValue, dropdownSecValue, amountValue);
        setOpertExpenInputValue('');
    };

    const handleOpertExpenTaxUpdate = async (dropdownValue, dropdownSecValue, amountValue) => {
        setGetLoading(true);
        if (dropdownValue === 'SalariesWages') {
            dropdownValue = "Salaries & Wages";
        } else if (dropdownValue === 'othroprexpensedetails') {
            dropdownValue = "Other Operating Expenses";
        }
        console.log(
            `Dropdown Value: ${dropdownValue}, Dropdown Sec Value: ${dropdownSecValue}, Amount Value ${amountValue}`
        );
        try {
            const apiUrl = `https://laravel.cashflow365.se/api/automation?year=${year}&comp_id=${CompanyId}`;
            const requestBody = {
                dropdown: dropdownSecValue,
                module: "operatingExpenses",
                subModule: dropdownValue,
                value: amountValue
            };
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            console.log('Update response:', data);
            setGetLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error updating equity:', error);
        }
    };

    if (getLoading) return <div className='loader-container'>
        <div class="loader"></div>
    </div>;
    
    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={Ai.formSections}
                                productAndServicesLabel="Product and Services"
                                selectPlaceholder="Select Value"
                                handleButtonClick={() => handleOpertExpenButtonClick()}
                                handleSelect1Change={handleOptionAuto}
                                handleSelect2Change={handleSelectDebtChange}
                                handleInputChange={(event) => handleOpertExpenInputChange(event)}
                                inputValue={OpertExpenInputValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
                            </div>
                            <FormSection
                                sections={salesData.formSections}
                                productAndServicesLabel="Select type Details"
                                selectPlaceholder="Select Value"
                                handleButtonClick={handleButtonClick}
                                handleInputChange={handleInputChange}
                                handleSelect1Change={handleSelect1Change}
                                handleSelect2Change={handleSelect2Change}
                                inputValues={sectionsState.inputValues}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        <CustomTable head={head} rows={rows} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default OperatingExpenses;
